const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shops/";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/search/top";

export const fetch_shops = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const delete_shop = (id) => {
  return fetch(`${API_HOST}${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const approve_shop = (shopId) => {
  return fetch(`${API_HOST}${shopId}/approve`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const reject_shop = (shopId) => {
  return fetch(`${API_HOST}${shopId}/reject`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const export_shops = () => {
  return fetch(API_HOST + "export", {
    method: "POST",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_shops = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=shop`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
