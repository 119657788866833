const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/shelters/";

export const fetch_shelters = () => {
  return fetch(API_HOST, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
