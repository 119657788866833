import React, { useEffect, useRef } from "react";
import selectArrow from "../../../../resources/themes/dashboard-v1/icons/select-arrow.svg";
import upload from "../../../../resources/themes/dashboard-v1/icons/upload.svg";
import uploadCode from "../../../../resources/themes/dashboard-v1/icons/upload2.svg";
import close from "../../../../resources/themes/dashboard-v1/icons/close-icon.svg";
import Select from "react-select";
import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  add_shop_products,
  delete_shop_products,
  edit_shop_products,
  fetch_shop_product,
  fetch_product_create_form,
  fetch_product_edit_form,
} from "../../../../services/shops/products";
import { Link, Navigate, useParams } from "react-router-dom";
import ImageUploader from "../../common/ImageUploader";
import DoubleRange from "../../common/DoubleRange";

let removedImages = [];

const ProductFormPage = () => {
  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const [shouldRedirectToProductView, setShouldRedirectToProductView] =
    useState(false);
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [age, setAge] = useState([0, 20]);
  const [pets, setPets] = useState([]);
  const [selectedPetTypes, setSelectedPetTypes] = useState([]);

  const editorRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    brand_name: "",
    description: "",
    description2: "",
    description3: "",
    price: "",
    stock_quantity: "",
    img_picture: "",
    images: [],
    index: 0,
    procurement_cost: 0,
    alert_quantity: 0,
    offer_type: 0,
    offer_value: 0,
    offer_start_time: new Date().toISOString().substr(0, 10),
    offer_end_time: new Date().toISOString().substr(0, 10),
    variant_parent_id: 1,
    shop_product_category_id: 1,
    from_age: 0,
    to_age: 20,
    pet_type_ids: [],
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_product_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_product_edit_form(id);
      };
    }
    formDepCallback().then((res) => {
      if (res.status === "ok") {
        setCategories(res.categories);
        setPets(res.petTypes);
        if (res.data) {
          setFormData(res.data);
          setSelectedProfileIndex(res.data.index);
          setAge([res.data.from_age, res.data.to_age])
          if(res.selected_pet_types){
            setSelectedPetTypes(res.selected_pet_types);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      fetch_shop_product(id).then((res) => {
        if (res.status === "ok") {
          setFormData({ ...res.data });
          setSelectedProfileIndex(res.data.index);
        }
      });
    }
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "pet_type_ids") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSelectProfile = (index) => {
    setSelectedProfileIndex(index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      img_picture: formData.images[index],
      index: index,
    }));
  };

  useEffect(() => {
    console.log(selectedProfileIndex);
  }, [selectedProfileIndex]);

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    setFormData((prevFormData) => ({
      ...prevFormData,
      img_picture: files[selectedProfileIndex],
      image: files[selectedProfileIndex],
      images: [...prevFormData.images, ...files],
      index: selectedProfileIndex,
    }));
  };

  useEffect(() => {
    console.log(formData);
    for (let i = 0; i < formData.images.length; i++) {
      formData[`images[${i}]`] = formData.images[i];
    }
  }, [formData]);

  const handleRemoveImage = (index) => {
    removedImages.push(formData.images[index].id);
    setFormData((prevFormData) => {
      const newImages = prevFormData.images.slice();
      newImages.splice(index, 1);

      const newIndex =
        index === selectedProfileIndex ? 0 : selectedProfileIndex;

      delete formData[`images[${index}]`];

      return {
        ...prevFormData,
        img_picture: newImages.length > 0 ? newImages[newIndex] : "",
        image: newImages.length > 0 ? newImages[newIndex] : "",
        images: newImages,
        index: newIndex,
        removedImages: removedImages,
      };
    });
  };

  const handleSelectInputChange = (name, selected) => {
    if (name === "pet_type_ids") {
      const selectedValues = selected.map((pet) => pet.value);
      handleTextInputChange({
        target: {
          name: name,
          value: selectedValues,
        },
      });
      setSelectedPetTypes(selected.map((option) => option.value));
    } else {
      handleTextInputChange({
        target: {
          name: name,
          value: selected.value,
        },
      });
    }   
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_shop_products(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_shop_products(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/products/list"} />;
  } else if (shouldRedirectToProductView) {
    return <Navigate to={"/admin/products/view/" + id} />;
  }

  return (
    <div className="page-content products py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Product" : "Add Product"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/products/list"}>
            Products
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.name : "New Product"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">PRODUCT INFO</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Product Images</label>
              <ImageUploader
                images={formData.images}
                onFileInputChange={handleFileInputChange}
                onRemoveImage={handleRemoveImage}
                onSelectProfile={handleSelectProfile}
                selectedProfileIndex={selectedProfileIndex}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Product Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleTextInputChange}
                placeholder={"Product Name"}
              />
              {validationErrors.name && (
                <span className="error-text text-danger">
                  {validationErrors.name[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Brand Name</label>
              <input
                type="text"
                name="brand_name"
                value={formData.brand_name}
                onChange={handleTextInputChange}
                placeholder={"Brand Name"}
              />
              {validationErrors.brand_name && (
                <span className="error-text text-danger">
                  {validationErrors.brand_name[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Product Category</label>
              <Select
                name="category"
                options={categories}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="service..."
                onChange={(selected) => {
                  handleSelectInputChange("shop_product_category_id", selected);
                }}
                value={categories.find(
                  (option) => option.value === formData.shop_product_category_id
                )}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Types</label>
              <Select
                name="pet"
                options={pets}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Pet Types..."
                isMulti
                isSearchable
                value={
                  selectedPetTypes.length > 0
                    ? pets.filter((pet) => selectedPetTypes.includes(pet.value))
                    : undefined
                }
                onChange={(selected) => {
                  handleSelectInputChange("pet_type_ids", selected);
                }}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Ages</label>
              <div className="d-flex align-items-center justify-content-center h-100">
                <DoubleRange age={age} setAge={setAge} />
              </div>
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Product Price</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleTextInputChange}
                placeholder={"Product Price"}
              />
              {validationErrors.price && (
                <span className="error-text text-danger">
                  {validationErrors.price[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Stock Quantity</label>
              <input
                type="number"
                name="stock_quantity"
                value={formData.stock_quantity}
                onChange={handleTextInputChange}
                placeholder={"Product Price"}
              />
              {validationErrors.stock_quantity && (
                <span className="error-text text-danger">
                  {validationErrors.stock_quantity[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3"></div>
          </div>

          {/*<div className='d-flex flex-md-row flex-column'>*/}
          {/*    <div className='d-flex flex-column gap-1 input-container mb-md-0 mb-3'>*/}
          {/*        <label className='fw-semibold'>Pet Options</label>*/}
          {/*        <Select*/}
          {/*            name="colors"*/}
          {/*            options={options}*/}
          {/*            className="basic-multi-select"*/}
          {/*            classNamePrefix="select"*/}
          {/*            placeholder="select"*/}
          {/*            onChange={(selected) => {handleSelectInputChange("colors", selected)}}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*    <div className='d-flex flex-column gap-1 input-container'>*/}
          {/*        <label className='fw-semibold'>Pet Age Range</label>*/}
          {/*        <Select*/}
          {/*            name="colors"*/}
          {/*            options={options}*/}
          {/*            className="basic-multi-select"*/}
          {/*            classNamePrefix="select"*/}
          {/*            placeholder="select"*/}
          {/*            onChange={(selected) => {handleSelectInputChange("colors", selected)}}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<div className='d-flex flex-md-row flex-column'>*/}
          {/*    <div className='d-flex flex-column gap-1 input-container mb-md-0 mb-3'>*/}
          {/*        <label className='fw-semibold'>Product Colors</label>*/}
          {/*        <Select*/}
          {/*            name="colors"*/}
          {/*            options={options}*/}
          {/*            className="basic-multi-select"*/}
          {/*            classNamePrefix="select"*/}
          {/*            placeholder="select"*/}
          {/*            onChange={(selected) => {handleSelectInputChange("colors", selected)}}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*    <div className='d-flex flex-column gap-1 input-container'>*/}
          {/*        <label className='fw-semibold'>Product Flavors</label>*/}
          {/*        <Select*/}
          {/*            name="colors"*/}
          {/*            options={options}*/}
          {/*            className="basic-multi-select"*/}
          {/*            classNamePrefix="select"*/}
          {/*            placeholder="select"*/}
          {/*            onChange={(selected) => {handleSelectInputChange("colors", selected)}}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*    <label className='fw-semibold'>Bar Code</label>*/}
          {/*    <div className='bar-code'>*/}
          {/*        <div className='barcode-upload-container'>*/}
          {/*            <div className='uplode-code custom-uploadCode d-flex justify-content-center align-items-center text-white px-3 py-2'>*/}
          {/*                <img className='me-2' src={uploadCode} alt="uploadCode" />*/}
          {/*                <span className='fw-bold'>Upload the barcode</span>*/}
          {/*            </div>*/}
          {/*            <input onChange={onChangeUploadCode} className='uplode-code' type="file" />*/}
          {/*        </div>*/}
          {/*        <input onChange={(e) => { setBarCode(e.target.value) }} value={barCodeImage == null ? '' : barCodeImage.name} className='full-width' type="text" placeholder='Enter the code of product , or upload  the barcode...' />*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<div>*/}
          {/*    <label className='fw-semibold'>Product Images</label>*/}
          {/*    <div className='product-image'>*/}
          {/*        {productImageVideo == null &&*/}
          {/*            <div className='upload-image'>*/}
          {/*                <img src={upload} alt="upload" />*/}
          {/*                <p className='m-0 d-md-block d-none'>Upload photos or videos of the product</p>*/}
          {/*            </div>*/}
          {/*        }*/}

          {/*        {productImageVideo != null &&*/}

          {/*            <div className='image-name h-100'>*/}
          {/*                <p className='m-0'>{productImageVideo.name}</p>*/}
          {/*                <img onClick={clearUploadPhoto} src={close} alt="close" />*/}
          {/*            </div>*/}
          {/*        }*/}
          {/*        <input onChange={onChangeUploadProduct} className='full-width' type="file" />*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<div className='full-width'>*/}
          {/*    <label className='fw-semibold'>Product Description</label>*/}
          {/*    <Editor*/}
          {/*        apiKey='6kjtrem2nwdl9ce3yeikmvb0hn5f0r6g7xw96heemizj690r'*/}
          {/*        onInit={(evt, editor) => editorRef.current = editor}*/}
          {/*        initialValue="<p style='color : #CFCFCF'>Describe your Product...</p>"*/}
          {/*        init={{*/}
          {/*            height: 200,*/}
          {/*            menubar: false,*/}
          {/*            plugins: [*/}
          {/*                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',*/}
          {/*                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',*/}
          {/*                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'*/}
          {/*            ],*/}
          {/*            toolbar: 'undo redo | blocks | ' +*/}
          {/*                'bold italic forecolor | alignleft aligncenter ' +*/}
          {/*                'alignright alignjustify | bullist numlist outdent indent | ' +*/}
          {/*                'removeformat | help',*/}

          {/*        }}*/}
          {/*        onChange={onChangeProductDescription}*/}
          {/*    />*/}
          {/*</div>*/}
        </div>

        {/*<div className='price-variation'>*/}
        {/*    <p className='product-info-title m-0 mb-4'>Product  PRICE & Variations</p>*/}

        {/*    <div className='variations'>*/}
        {/*        <span>Variation 01</span>*/}
        {/*        <span>Variation 02</span>*/}
        {/*        <span>+ Add new Variation</span>*/}
        {/*    </div>*/}

        {/*    <div className='d-flex flex-column gap-3 inputs-container'>*/}
        {/*        <div className='d-flex flex-lg-row flex-column gap-1'>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Product Price</label>*/}
        {/*                <input onChange={(e) => { setProductPrice(e.target.value) }} type="text" placeholder='EX: $999' />*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Procurement cost<span className='fw-normal'>(optional)</span></label>*/}
        {/*                <input onChange={(e) => { setProductCost(e.target.value) }} type="text" placeholder='EX: $999' />*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Tax Enabled</label>*/}
        {/*                <Select*/}
        {/*                    isMulti*/}
        {/*                    name="colors"*/}
        {/*                    options={options}*/}
        {/*                    className="basic-multi-select"*/}
        {/*                    classNamePrefix="select"*/}
        {/*                    placeholder="select"*/}
        {/*                    onChange={onChangePetOptions}*/}
        {/*                />*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className='d-flex flex-lg-row flex-column gap-1'>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Product Unit<span className='fw-normal'>(optional)</span></label>*/}
        {/*                <div className='position-relative'>*/}
        {/*                    <div className='custom-dropdwon'>*/}
        {/*                        <Select*/}
        {/*                            isMulti*/}
        {/*                            name="colors"*/}
        {/*                            options={options}*/}
        {/*                            className="basic-multi-select"*/}
        {/*                            classNamePrefix="select"*/}
        {/*                            placeholder="select"*/}
        {/*                            onChange={onChangePetOptions}*/}
        {/*                        />*/}
        {/*                    </div>*/}
        {/*                    <input onChange={(e) => { setProductUnit(e.target.value) }} type="text" placeholder='EX: 300' />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Product Stock<span className='fw-normal'>(optional)</span></label>*/}
        {/*                <input onChange={(e) => { setProductStock(e.target.value) }} type="text" placeholder='EX: 100' />*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Alert Quantity<span className='fw-normal'>(optional)</span></label>*/}
        {/*                <input onChange={(e) => { setAlertQuantity(e.target.value) }} type="text" placeholder='EX: 50' />*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className='d-flex flex-lg-row flex-column gap-1'>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Offer Applied</label>*/}
        {/*                <Select*/}
        {/*                    isMulti*/}
        {/*                    name="colors"*/}
        {/*                    options={options}*/}
        {/*                    className="basic-multi-select"*/}
        {/*                    classNamePrefix="select"*/}
        {/*                    placeholder="select"*/}
        {/*                    onChange={onChangePetOptions}*/}
        {/*                />*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Offer Details</label>*/}
        {/*                <Select*/}
        {/*                    isMulti*/}
        {/*                    name="colors"*/}
        {/*                    options={options}*/}
        {/*                    className="basic-multi-select"*/}
        {/*                    classNamePrefix="select"*/}
        {/*                    placeholder="select"*/}
        {/*                    onChange={onChangePetOptions}*/}
        {/*                />*/}
        {/*            </div>*/}
        {/*            <div className='d-flex flex-column gap-1 input-container'>*/}
        {/*                <label className='fw-semibold'>Offer Duration<span className='fw-normal'>(optional)</span></label>*/}
        {/*                <div className='position-relative'>*/}
        {/*                    <div className='custom-dropdwon'>*/}
        {/*                        <select onChange={onChangeOfferDurationDropDown} name="alert-quantity" id="alert-quantity">*/}
        {/*                            <option value="Hours">Hours</option>*/}
        {/*                            <option value="Months">Months</option>*/}
        {/*                            <option value="Years">Years</option>*/}
        {/*                        </select>*/}
        {/*                    </div>*/}
        {/*                    <input onChange={(e) => { setOfferDuration(e.target.value) }} type="text" placeholder='EX: 72h' />*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary px-5 my-4"} onClick={handleSave}>
            Save
          </button>
        </div>
        {/*</div>*/}
      </div>
    </div>
  );
};

export default ProductFormPage;
