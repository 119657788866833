import React, { useState, useEffect } from "react";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { updateRegistrationData } from "../../../services/settings.js";
import verified from "../../../resources/themes/dashboard-v1/icons/verified.svg";

import { notify_promise } from "../../../services/utils/toasts";
import { notify_error } from "../../../services/utils/toasts";

import upload from "../../../resources/themes/dashboard-v1/icons/upload.svg";

const PersonalInfo = (props) => {
  const { formData, setFormData } = props;
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number_cc: "",
    phone_number: "", 
    picture: "",
    government_id: "",
  });

  const [preValidationData, setPreValidationData] = useState(
    formData.representerInfo
  );

  useEffect(() => {
    setPreValidationData(formData.representerInfo);
  }, [formData.representerInfo]);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setPreValidationData((prevData) => ({
      ...prevData,
      picture: file,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      representerInfo: {
        ...prevFormData.representerInfo,
        picture: file,
      },
    }));
  };

  const handleGovInputChange = (event) => {
    const file = event.target.files[0];
    setPreValidationData((prevData) => ({
      ...prevData,
      government_id: file,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      representerInfo: {
        ...prevFormData.representerInfo,
        government_id: file,
      },
    }));
  };

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    setPreValidationData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTextInputBlur = (event) => {
    const { name } = event.target;
    const value = preValidationData[name];

    if (validate(name, value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        representerInfo: {
          ...prevFormData.representerInfo,
          [name]: value,
        },
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handlePhoneNumberChange = (value) => {
    setPreValidationData((prevData) => ({ ...prevData, phone_number: value }));
  };

  const handlePhoneNumberBlur = () => {
    const value = preValidationData.phone_number;
    if (typeof value === "string") {
      const digitCount = (value.match(/\d/g) || []).length;

      if (digitCount > 1) {
        const parsedPhoneNumber = parsePhoneNumber(value);

        if (parsedPhoneNumber) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            representerInfo: {
              ...prevFormData.representerInfo,
              phone_number: value,
              phone_number_cc: parsedPhoneNumber.countryCallingCode,
            },
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone_number: "",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone_number: "Full Phone number is required.",
          }));
        }
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone_number: "Full Phone number is required.",
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone_number: "Full Phone number is required.",
      }));
    }
  };

  const validate = (name, value) => {
    let isValid = true;

    if (name === "first_name" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        first_name: "first name is required.",
      }));
      isValid = false;
    }
    if (name === "last_name" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        last_name: "last name is required.",
      }));
      isValid = false;
    }

    if (name === "email" && value.trim() === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      isValid = false;
    }
    if (name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
      isValid = false;
    }

    return isValid;
  };

  async function handleUpdateRegistration(data) {
    notify_promise(
      new Promise((resolve, reject) => {
        updateRegistrationData(data)
          .then((response) => {
            if (response.status === "ok") {
              console.log("success");
              resolve(response);
            } else {
              notify_error("Can't Update");
              reject(response);
            }
          })
          .catch((e) => {
            notify_error("Can't Update");
          });
      })
    );
  }

  return (
    <div className=" flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="inputs-container bg-white">
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">PERSONAL INFO</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">First Name</label>
              <input
                type="text"
                name="first_name"
                value={preValidationData.first_name || ""}
                onChange={handleTextInputChange}
                onBlur={handleTextInputBlur}
                placeholder={"First Name"}
              />
              {errors.first_name && (
                <div className="alert alert-danger mt-2">
                  {errors.first_name}
                </div>
              )}
            </div>

            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Last Name</label>
              <input
                type="text"
                name="last_name"
                value={preValidationData.last_name || ""}
                onChange={handleTextInputChange}
                onBlur={handleTextInputBlur}
                placeholder={"Last Name"}
              />
              {errors.last_name && (
                <div className="alert alert-danger mt-2">
                  {errors.last_name}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Business Email</label>
              <input
                type="text"
                name="email"
                value={preValidationData.email || ""}
                onChange={handleTextInputChange}
                onBlur={handleTextInputBlur}
                placeholder={"Business Email"}
              />
              {errors.email && (
                <div className="alert alert-danger mt-2">{errors.email}</div>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">
                Phone Number <img src={verified} alt="verified" />
              </label>
              <PhoneInput
                defaultCountry="AE"
                international
                withCountryCallingCode
                value={preValidationData.phone_number || ""}
                onChange={handlePhoneNumberChange}
                onBlur={handlePhoneNumberBlur}
              />
              {errors.phone_number && (
                <div className="alert alert-danger mt-2">
                  {errors.phone_number}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Profile Picture</label>
              <div className="product-image w-100">
                {preValidationData.picture == null && (
                  <div className="upload-image">
                    <img src={upload} alt="upload" />
                    <p className="m-0 d-md-block d-none">
                      Upload Profile Picture
                    </p>
                  </div>
                )}

                {preValidationData.picture === null && (
                  <input
                    type="file"
                    name="picture"
                    onChange={handleFileInputChange}
                    accept="image/jpeg, image/png, image/gif"
                  />
                )}

                {preValidationData.picture && (
                  <div>
                    <label htmlFor="imageUpload" className="image-input-label">
                      {preValidationData.picture instanceof File ? (
                        <img
                          height={150}
                          src={URL.createObjectURL(preValidationData.picture)}
                          alt=""
                        />
                      ) : (
                        <img
                          height={150}
                          src={preValidationData.picture}
                          alt=""
                        />
                      )}
                      <input
                        id="imageUpload"
                        type="file"
                        name="picture"
                        onChange={handleFileInputChange}
                        accept="image/jpeg, image/png, image/gif"
                        className="hidden-input"
                      />
                    </label>
                  </div>
                )}
              </div>
              {errors.picture && (
                <div className="alert alert-danger mt-2">{errors.picture}</div>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Government ID</label>
              <div className="product-image w-100">
                {preValidationData.government_id == null && (
                  <div className="upload-image">
                    <img src={upload} alt="upload" />
                    <p className="m-0 d-md-block d-none">Upload ID Image</p>
                  </div>
                )}

                <input
                  onChange={handleGovInputChange}
                  className="full-width"
                  type="file"
                  accept="application/pdf"
                />
              </div>
              {errors.government_id && (
                <div className="alert alert-danger mt-2">
                  {errors.government_id}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers py-4 px-4">
            <button
              className={"btn btn-primary pl-5 pr-5"}
              onClick={() => handleUpdateRegistration(formData)}
            >
              {" "}
              Request Updates or changes{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
