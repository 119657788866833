import React, { useState, useEffect } from 'react';
import show from '../../resources/themes/dashboard-v1/icons/show.svg'
import {resetPassword} from '../../services/auth.js'
import { useNavigate } from 'react-router-dom';

const NewPassword = (props) => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [passwordType, setPasswordType] = useState("text")
    const [confirmPasswordType, setConfirmPasswordType] = useState("text")
    const [saveData, setSaveData] = useState(false)

    const navigate = useNavigate();


    const ShowHidePassword = (field)=>{
        if(field === "password"){
            passwordType === "text" ? setPasswordType("password") : setPasswordType("text")
        }
        else{
            confirmPasswordType === "text" ? setConfirmPasswordType("password") : setConfirmPasswordType("text")
        }
    }

    const isPasswordValid = (password) => {
        const minLength = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/;
        const hasUpperCase = /[A-Z]/;
        const hasNumberOrSpecialChar = /[\d!@#$%&]/;
      
        return (
          minLength.test(password) &&
          hasUpperCase.test(password) &&
          hasNumberOrSpecialChar.test(password)
        );
    };

    const validate = () => {
        let isValid = false;
        if (!isPasswordValid(password)) {
            setError('Password should have minimium 8 chars, 1 capital letter, and 1 symbol/number.');
            isValid = false;
        }
        else if (password != confirmPassword) {
            setError('Confirm password correctly');
            isValid = false;
        }
        else {
            isValid = true;
        }
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let phoneNumber = '';
        let phoneNumberCC = '';

        if (props.phoneData) {
            phoneNumber = props.phoneData.phone_number;
            phoneNumberCC = props.phoneData.phone_number_cc;
        }

        if(validate() && phoneNumber && phoneNumberCC){
            resetPassword(password, phoneNumber, phoneNumberCC)
            .then((response) => {
                if (response.status === "ok") {
                  setSaveData(true);
                  console.log("Registration successful!");
                } else {
                    setError("Can't update Password! please ensure to enter the phone number used while signup.");
                    console.error("Registration error:", response.message);
                }
              })
              .catch((error) => {
                setError("An error occurred while saving registration data.");
                console.error("Fetch error:", error);
              });
        }
    }

    useEffect(() => {
        if(saveData) {
            console.log("update success");
            navigate('/login');
        }
    }, [saveData, navigate]);


    return (
        <div className={props.class} method="POST">
            <h1 className="mb-1 text-center">Reset your password</h1>
            <p className="form-help">Enter the new password.</p>
            {error && <div className="alert alert-danger mt-2">{error}</div>}
            <div className="mb-5 position-relative">
                <div className="mb-3">
                    <label htmlFor="email" className="form-label ">Password</label>
                    <div className='position-relative'>
                        <img onClick={()=> ShowHidePassword('password')} className='show-icon' src={show} alt="show" />
                        <input
                            id='password'
                            type={passwordType}
                            className="form-control"
                            name="password"
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                            />
                    </div>
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label ">Confirm Password</label>
                    <div className='position-relative'>
                        <img onClick={()=> ShowHidePassword('confirm')} className='show-icon' src={show} alt="show" />
                        <input
                            id='confirmPassword'
                            type={confirmPasswordType}
                            className="form-control"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => { setconfirmPassword(e.target.value) }}
                            />
                    </div>
                </div>
            </div>

            <button type="submit" onClick={handleSubmit} className="btn btn-primary w-100 py-2 fw-bold">Continue</button>
        </div>
        
    );
}

export default NewPassword;
