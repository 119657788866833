import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  add_product_categories,
  edit_product_categories,
  fetch_product_category_edit_form,
  fetch_product_category_create_form,
} from "../../../services/admin/product_categories";
import Select from "react-select";
import SingleFileUploader from "../../SingleFileUploader";
import { Link, Navigate, useParams } from "react-router-dom";
import TextEditor from "../TextEditor";

const ProductCategoryFormPage = () => {
  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: "",
    background_image: "",
    parent_category_id: 1,
  });

  const [validationErrors, setValidationErrors] = useState({});

  const [categories, setCategories] = useState([]);
  const [styles, setStyles] = useState([]);

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_product_category_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_product_category_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if(res.categories){
          setCategories(res.categories);
        }
        if (res.data) {
          setFormData(res.data);
        }
        if (res.styles) {
          setStyles(res.styles);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleFileInputChange = (fieldName, fieldValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: fieldValue,
    }));
  };

  const handleSelectInputChange = (name, selected) => {
    handleTextInputChange({
      target: {
        name: name,
        value: selected.value,
      },
    });
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_product_categories(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_product_categories(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/product-categories/list"} />;
  }

  return (
    <div className="page-content py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Product Category" : "Add Product Category"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/product-categories/list"}>
            Product Categories
          </Link>
          <span>&gt;</span>
          <span>
            {id !== undefined ? formData.title : "New Product Category"}
          </span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Product Category Image</label>
              <SingleFileUploader
                options={{
                  name: "image",
                  currentPhoto: formData.image,
                  onPhotoChange: (fieldValue) =>
                    handleFileInputChange("image", fieldValue),
                  type: "file",
                }}
                type="image"
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Background Image</label>
              <SingleFileUploader
                options={{
                  name: "background_image",
                  currentPhoto: formData.background_image,
                  onPhotoChange: (fieldValue) =>
                    handleFileInputChange("background_image", fieldValue),
                  type: "file",
                }}
                type="image"
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Product Category Title</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleTextInputChange}
                placeholder={"Product Category Title"}
              />
              {validationErrors.title && (
                <span className="error-text text-danger">
                  {validationErrors.title[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Parent Category</label>
              <Select
                name="category"
                options={categories}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="product category..."
                onChange={(selected) => {
                  handleSelectInputChange("parent_category_id", selected);
                }}
                value={categories.find(
                  (option) => option.value === formData.parent_category_id
                )}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Element Style</label>
              <Select
                name="styles"
                options={styles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="style name..."
                onChange={(selected) => {
                  handleSelectInputChange("style_id", selected);
                }}
                value={styles.find(
                  (option) => option.value === formData.style_id
                )}
              />
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3"></div>
          </div>

          <div className="row pr-5">
            <div className="col-12 mb-5">
              <label className="fw-semibold">
                Product Category Description
              </label>
              <TextEditor
                value={formData.description}
                onChange={(content) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: content,
                  }));
                }}
                placeholder="Relevant Product Category Notes"
              />
            </div>
          </div>
        </div>

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary p-10-50"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCategoryFormPage;
