import React from "react";

const VaccinesEntry = ({ entry }) => {
  const {
    vaccine_type,
    description,
    vaccine_dosage_injected,
    vaccine_dosage_total,
    administration_date
   } = entry;

  return (
    <div className="col-12 py-2 vaccine-entry border-bottom">
      <div className="d-flex">
          <div className={"flex-grow-1"}>
              <h4 className={"text-grey"}>{vaccine_type}</h4>
              <p className={"p-2"}>{description}</p>
          </div>
          <div className="d-flex justify-content-end align-items-end flex-column gap-1">
              <div className={"badge bg-info"}>{vaccine_dosage_injected} / {vaccine_dosage_total}</div>
              <div className={"text-grey min-w-100"}>{administration_date}</div>
          </div>
      </div>
    </div>
  );
};

export default VaccinesEntry;
