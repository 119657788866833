import React, { useEffect, useState } from "react";
import AdoptionEntry from "./shelter/adoptions/AdoptionEntry";
import VaccinesEntry from "./shelter/pets/VaccinesEntry";
import BookingEntry from "../dashboard/clinic/BookingEntry";
import UserEntry from "../dashboard/admin/UserEntry";
import OrderEntry from "../dashboard/shop/OrderEntry";
import {Link} from "react-router-dom";


const CardListing = ({ title, type, entries, viewAllLink }) => {
    
  return (
    <div className="card-listing-component card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h4 className="fw-bold">{title}</h4>
          {viewAllLink !== undefined ? (
            <Link to={viewAllLink} className="view-all">
              View all
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="row mt-2 mb-1">
          {type === "adoptions" &&
            entries.map((entry, index) => (
              <AdoptionEntry key={index} entry={entry} />
            ))}
          {type === "bookings" &&
            entries.map((entry, index) => (
              <BookingEntry key={index} entry={entry} />
            ))}
          {type === "users" &&
            entries.map((entry, index) => (
              <UserEntry key={index} entry={entry} />
            ))}
          {type === "orders" &&
            entries.map((entry, index) => (
              <OrderEntry key={index} entry={entry} />
            ))}
          {type === "vaccines" &&
            entries.map((entry, index) => (
              <VaccinesEntry key={index} entry={entry} />
            ))}

          {entries.length === 0 ? (
            <p className={"p-3"}>No {title} entries</p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default CardListing;
