const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/auth/";

export function authenticate(email, password) {
  return fetch(API_HOST + "login", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email: email,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.status === "error") {
        return response;
      }

      return fetch_authed_user();
    })
    .catch((error) => {
      console.error(error);
    });
}

export const unauthenticate = () => {
  return fetch(API_HOST + "logout", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "ok") {
        localStorage.clear();
        localStorage.setItem("user_logged_in", "false");
      }

      return res;
    });
};

const fetch_authed_user = () => {
  return fetch(API_HOST + "user", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "ok") {
        localStorage.setItem("user_logged_in", "true");
        localStorage.setItem("user_id", res.data.user.id);
        localStorage.setItem("shop_id", res.data.user.shop.id);
        localStorage.setItem("user_first_name", res.data.user.first_name);
        localStorage.setItem("user_last_name", res.data.user.last_name);
        localStorage.setItem("user_picture", res.data.user.picture);

        localStorage.setItem("user_menus", JSON.stringify(res.data.menus));
        localStorage.setItem("user_role", res.data.role);
        localStorage.setItem("per_page", 10);
      }

      return res;
    });
};

export function saveRegistrationData(registrationData) {
  console.log(registrationData);
  const { representerInfo, businessInfo } = registrationData;

  registrationData.businessInfo.name = businessInfo.shop_name;
  registrationData.businessInfo.description = businessInfo.business_description;

  const formData = new FormData();
  formData.append("license", businessInfo.license);
  formData.append("business_logo", businessInfo.business_logo);
  formData.append("government_id", representerInfo.government_id);

  return fetch(API_HOST + "register", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(registrationData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "ok") {
        return upload_registration_documents(formData).then(
          (uploadResponse) => {
            if (uploadResponse.status === "ok") {
              return {
                status: "ok",
                failed: "none",
              };
            }
            return {
              status: "error",
              failed: "uploads",
            };
          }
        );
      }

      return {
        status: "error",
        failed: "data",
      };
    })
    .catch((error) => {
      console.error(error);
      return { error };
    });
}

const upload_registration_documents = (formData) => {
  return fetch(API_HOST + "register-documents", {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
};

export function resetPassword(password, phoneNumber, phoneNumberCC) {
  console.log(API_HOST);
  return fetch(API_HOST + "reset-password", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      phone_number_cc: phoneNumberCC,
      phone_number: phoneNumber,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
}

export function auth_ping() {
  return fetch(API_HOST + "ping", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
};