import React from "react";
import DynamicChart from "./common/DynamicChart";

const StatsCard = ({ stat, icon, border }) => {
  let cardClass = "";
  if (border) {
    cardClass += " card-border";
  }
  
  return (
    <div className={`card ${cardClass}`}>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h5 className="card-title">{stat.title}</h5>
          <div className="dropdown">
            <a type="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img src={icon} alt="options" />
            </a>
            <ul class="dropdown-menu">
              <li>
                <a class="dropdown-item" href={`admin/chart/${stat.key}`}>
                  Focus
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {stat.subtitle && <p className="card-text mt-4">{stat.subtitle}</p>}
            <p className="stat-num">{stat.value}</p>
            {!stat.subtitle && <h5>+5%</h5>}
          </div>
          <div className="col-6 p-0 d-flex align-items-center">
            <DynamicChart
              data={stat.chart_data}
              type={stat.chart_type}
              width={120}
              height={80}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
