import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";

import DashboardHeader from "./dashboardHeader";
import DashboardSideBar from "./dashboardSideBar";
import ProductFormPage from "./shop/products/ProductFormPage";
import ProductViewPage from "./shop/products/ProductViewPage";
import ProductListingPage from "./shop/products/ProductListingPage";
import OrderListingPage from "./shop/orders/OrderListingPage";
import PetListingPage from "./shelter/pets/PetListingPage";
import PetFormPage from "./shelter/pets/PetFormPage";
import DashboardMain from "./DashboardMain";
import StatsChartViewPage from "./StatsChartViewPage";
import Profile from "./Profile";
import AccountListingPage from "./AccountListingPage";
import GLOBAL_STATE from "../../services/utils/global-state";
import AdoptionListingPage from "./shelter/adoptions/AdoptionListingPage";
import PetViewPage from "./shelter/pets/PetViewPage";
import ShelterSupportPage from "./shelter/ShelterSupportPage";
import AdminSupportPage from "./admin/AdminSupportPage";
import AdminSettingsPage from "./admin/AdminSettingsPage";
import PetTransferListingPage from "./shelter/PetTransferListingPage";
import ShopListingPage from "./admin/ShopListingPage";
import UserListingPage from "./admin/UserListingPage";
import BlogListingPage from "./admin/BlogListingPage";
import DocumentListingPage from "./admin/DocumentListingPage";
import BlogFormPage from "./admin/BlogFormPage";
import DocumentFormPage from "./admin/DocumentFormPage";
import ReportsPage from "./admin/ReportsPage";
import ProductCategoryListingPage from "./admin/ProductCategoryListingPage";
import ProductCategoryFormPage from "./admin/ProductCategoryFormPage";
import AdminServiceCategoryListingPage from "./admin/AdminServiceCategoryListingPage";
import StylesListingPage from "./admin/StylesListingPage";
import StylesFormPage from "./admin/StylesFormPage";
import ServiceCategoryFormPage from "./admin/ServiceCategoryFormPage";
import HomeBannersListingPage from "./admin/HomeBannersListingPage";
import HomeBannersFormPage from "./admin/HomeBannersFormPage";

import ServiceCategoryListingPage from "./clinic/services/ServiceCategoryListingPage";
import ServiceFormPage from "./clinic/services/ServiceFormPage";
import ServiceListingPage from "./clinic/services/ServiceListingPage";
import BookingListingPage from "./clinic/BookingListingPage";
import EmployeeListingPage from "./clinic/EmployeeListingPage";
import EmployeeFormPage from "./clinic/EmployeeFormPage";
import AppointmentFormPage from "./clinic/AppointmentFormPage";
import MyCalendar from "./clinic/Calender";
import SettingsPage from "./common/SettingsPage";
import AccountManagerForm from "./shop/settings/accountManagerForm";

const Home = (props) => {
  const userRole = localStorage.getItem("user_role");

  return (
    <section className="page-section home bg-lightgray position-relative">
      <div className="d-flex flex-grow-1 flex-shrink-1">
        <DashboardSideBar />
        <div
          className={"d-flex flex-column flex-grow-1 page-content-container"}
        >
          <DashboardHeader />
          <PageRoutes userRole={userRole} />
        </div>
      </div>
    </section>
  );
};

export default Home;

const PageRoutes = ({ userRole }) => {
  return (
    <Routes>
      <Route path={"/"} element={<DashboardMain />}></Route>
      <Route path={"/chart/:stat"} element={<StatsChartViewPage />}></Route>
      {/*------------------- ADMIN ROUTES -------------------*/}

      {userRole === "super-admin" && (
        <>
          <Route
            path={"/sellers/list/:shopStatus?"}
            element={<ShopListingPage />}
          ></Route>
          <Route path={"/users/list"} element={<UserListingPage />}></Route>
          <Route path={"/blogs/list"} element={<BlogListingPage />}></Route>
          <Route path={"/blogs/add"} element={<BlogFormPage />} />
          <Route path={"/blogs/edit/:id"} element={<BlogFormPage />} />
          <Route
            path={"/documents/list"}
            element={<DocumentListingPage />}
          ></Route>
          <Route path={"/documents/add"} element={<DocumentFormPage />} />
          <Route path={"/documents/edit/:id"} element={<DocumentFormPage />} />
          <Route
            path={"/product-categories/list"}
            element={<ProductCategoryListingPage />}
          ></Route>
          <Route
            path={"/product-categories/add"}
            element={<ProductCategoryFormPage />}
          />
          <Route
            path={"/product-categories/edit/:id"}
            element={<ProductCategoryFormPage />}
          />
          <Route
            path={"/service-categories/list"}
            element={<AdminServiceCategoryListingPage />}
          ></Route>
          <Route
            path={"/service-categories/add"}
            element={<ServiceCategoryFormPage />}
          />
          <Route
            path={"/service-categories/edit/:id"}
            element={<ServiceCategoryFormPage />}
          />
          <Route
            path={"/element-styles/list"}
            element={<StylesListingPage />}
          ></Route>
          <Route path={"/element-styles/add"} element={<StylesFormPage />} />
          <Route
            path={"/element-styles/edit/:id"}
            element={<StylesFormPage />}
          />
          <Route
            path={"/home-banners/list"}
            element={<HomeBannersListingPage />}
          ></Route>
          <Route path={"/home-banners/add"} element={<HomeBannersFormPage />} />
          <Route
            path={"/home-banners/edit/:id"}
            element={<HomeBannersFormPage />}
          />
          <Route
            path={"/account-manager"}
            element={<AccountListingPage />}
          ></Route>
          <Route
            path={"/accounts/add"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/accounts/edit/:id"}
            element={<AccountManagerForm />}
          ></Route>
          <Route path={"/support"} element={<AdminSupportPage />}></Route>
          <Route path={"/reports"} element={<ReportsPage />}></Route>
          <Route
            path={"/settings/:tabName?"}
            element={<AdminSettingsPage />}
          ></Route>
        </>
      )}
      {/*------------------- SHOP ROUTES -------------------*/}
      {(userRole === "vendor-shop" ||
        userRole.split(",").includes("vendor-shop")) && (
        <>
          <Route
            path={"/products/list"}
            element={<ProductListingPage />}
          ></Route>
          <Route path={"/products/add"} element={<ProductFormPage />}></Route>
          <Route
            path={"/products/edit/:id"}
            element={<ProductFormPage />}
          ></Route>
          <Route
            path={"/products/view/:id"}
            element={<ProductViewPage />}
          ></Route>

          <Route path={"/orders"} element={<OrderListingPage />}></Route>
          <Route
            path={"/accounts/add"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/accounts/edit/:id"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/account-manager"}
            element={<AccountListingPage />}
          ></Route>
          <Route
            path={"/settings/:tabName?"}
            element={<SettingsPage />}
          ></Route>
        </>
      )}

      {/*------------------- SHELTER ROUTES -------------------*/}
      {(userRole === "vendor-shelter" ||
        userRole.split(",").includes("vendor-shelter")) && (
        <>
          <Route
            path={"/pets/list/:petType?"}
            element={<PetListingPage />}
          ></Route>

          <Route path={"/pets/view/:id"} element={<PetViewPage />}></Route>

          <Route path={"/pets/add"} element={<PetFormPage />}></Route>
          <Route path={"/pets/edit/:id"} element={<PetFormPage />}></Route>
          <Route
            path={"/adoptions/list"}
            element={<AdoptionListingPage />}
          ></Route>

          <Route path={"/profile"} element={<Profile />}></Route>

          <Route
            path={"/adoptions/list/:requestedAdoptionId"}
            element={<AdoptionListingPage />}
          ></Route>
          <Route
            path={"/transfers/:transferState?"}
            element={<PetTransferListingPage />}
          ></Route>
          <Route path={"/support"} element={<ShelterSupportPage />}></Route>
          <Route
            path={"/accounts/add"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/accounts/edit/:id"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/account-manager"}
            element={<AccountListingPage />}
          ></Route>
          <Route
            path={"/settings/:tabName?"}
            element={<SettingsPage />}
          ></Route>
        </>
      )}

      {/*------------------- Clinic ROUTES -------------------*/}
      {(userRole === "vendor-clinic" ||
        userRole.split(",").includes("vendor-clinic")) && (
        <>
          <Route path={"/calendar"} element={<MyCalendar />} />
          <Route
            path={"/services/appointments/:bookingStatus?"}
            element={<BookingListingPage />}
          />
          <Route path={"/employees"} element={<EmployeeListingPage />} />
          <Route path={"/employees/add"} element={<EmployeeFormPage />} />
          <Route path={"/employees/edit/:id"} element={<EmployeeFormPage />} />
          <Route
            path={"/services/byCategory/:parentCategoryID"}
            element={<ServiceListingPage />}
          />

          <Route
            path={"/services/categories"}
            element={<ServiceCategoryListingPage />}
          ></Route>

          <Route path={"/services/add"} element={<ServiceFormPage />}></Route>
          <Route
            path={"/services/edit/:id"}
            element={<ServiceFormPage />}
          ></Route>

          <Route
            path={"/appointments/add"}
            element={<AppointmentFormPage />}
          ></Route>
          <Route
            path={"/appointments/edit/:id"}
            element={<AppointmentFormPage />}
          ></Route>

          <Route path={"/support"} element={<ShelterSupportPage />}></Route>
          <Route
            path={"/accounts/add"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/accounts/edit/:id"}
            element={<AccountManagerForm />}
          ></Route>
          <Route
            path={"/account-manager"}
            element={<AccountListingPage />}
          ></Route>
          <Route
            path={"/settings/:tabName?"}
            element={<SettingsPage />}
          ></Route>
        </>
      )}
    </Routes>
  );
};
