const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/settings/";

export const fetch_dynamic_settings = async () => {
    return fetch(API_HOST + "edit", {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
}

export const save_settings = async (fieldValues, attachmentFieldValues) => {
    try {
        const [fieldsResponse, attachmentsResponse] = await Promise.all([
            save_settings_fields(fieldValues),
            save_settings_attachments(attachmentFieldValues)
        ]);

        const fieldsResponseData = await fieldsResponse;
        const attachmentsResponseData = await attachmentsResponse;

        return { fieldsResponseData, attachmentsResponseData };
    } catch (error) {
        // Handle error and possibly re-throw
        throw error;
    }
};


const save_settings_fields = (groupedFields) => {
    return fetch(API_HOST + "update", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(groupedFields),
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((res) => res.json())
}

const save_settings_attachments = (groupedAttachmentFields) => {
    const formData = new FormData();

    Object.keys(groupedAttachmentFields).forEach((groupKey) => {
        Object.keys(groupedAttachmentFields[groupKey]).forEach((fieldName) => {
            const inputName = groupKey + "_" + fieldName
            const input = document.getElementsByName(fieldName)[0]
            const file = input.files[0]
            formData.append(inputName, file);
        })
    });

    return fetch(API_HOST + "update-attachments", {
        method: "POST",
        credentials: "include",
        secure: true,
        body: formData,
        headers: {},
    }).then((res) => res.json());
}