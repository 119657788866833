import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  add_styles,
  edit_styles,
  fetch_style_edit_form,
  fetch_style_create_form,
} from "../../../services/admin/styles";
import Select from "react-select";
import SingleFileUploader from "../../SingleFileUploader";
import StylePreviewBox from "./StylePreviewBox";
import { Link, Navigate, useParams } from "react-router-dom";

const StylesFormPage = () => {
  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    background_image: "",
    background_color: "#ffffff",
    foreground_color: "#ffffff",
    font_color: "#000000",
    font_color_secondary: "#000000",
    font_size: "16",
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_style_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_style_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if (res.data) {
          setFormData(res.data);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleFileInputChange = (fieldValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      background_image: fieldValue,
    }));
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_styles(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_styles(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/element-styles/list"} />;
  }

  return (
    <div className="page-content py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Style" : "Add Style"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/element-styles/list"}>
            Element Styles
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.name : "New Style"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <div className="row">
            <div className="col-8">
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Style Name</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleTextInputChange}
                    placeholder={"style name"}
                  />
                  {validationErrors.name && (
                    <span className="error-text text-danger">
                      {validationErrors.name[0]}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3"></div>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Background Image</label>
                  <SingleFileUploader
                    options={{
                      name: "background_image",
                      currentPhoto: formData.background_image,
                      onPhotoChange: handleFileInputChange,
                      type: "file",
                    }}
                    type="image"
                  />
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Background Color</label>
                  <input
                    type="color"
                    name="background_color"
                    value={formData.background_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Foreground Color</label>
                  <input
                    type="color"
                    name="foreground_color"
                    value={formData.foreground_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Font Size (in px)</label>
                  <input
                    type="number"
                    name="font_size"
                    value={formData.font_size}
                    onChange={handleTextInputChange}
                    placeholder={"font size in px"}
                  />
                </div>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Font Color</label>
                  <input
                    type="color"
                    name="font_color"
                    value={formData.font_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Font Color Secondary</label>
                  <input
                    type="color"
                    name="font_color_secondary"
                    value={formData.font_color_secondary}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
              </div>
            </div>
            <div className="col-4">
              <StylePreviewBox formData={formData} />
            </div>
          </div>
        </div>

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary p-10-50"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default StylesFormPage;
