const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/";

export const fetch_support_tickets = () => {
  return fetch(API_HOST + "support", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_shelter_support_tickets = () => {
  return fetch(API_HOST + "shelter/support", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_support_ticket = (data) => {
  return fetch(API_HOST + "support", {
    method: "POST",
    credentials: "include",
    secure: true,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const mark_resolved = (id) => {
  return fetch(API_HOST + `support/${id}/mark-as-resolved`, {
    method: "PUT",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
