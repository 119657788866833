import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  add_shop_pets,
  delete_shop_pets,
  edit_shop_pets,
  fetch_pet_edit_form,
  fetch_pet_create_form,
} from "../../../../services/shelters/pets";
import ImageUploader from "../../common/ImageUploader";
import { Link, Navigate, useParams } from "react-router-dom";
import SelectBox from "../../common/SelectBox";
import TextEditor from "../../TextEditor";
import PawCheckbox from "../../common/Checkbox";

const flagFields = [
  {
    name: "good_with_kids",
    label: "Good with kids",
  },
  {
    name: "good_with_other_pets",
    label: "Good with other pets",
  },
  {
    name: "available_for_fostering",
    label: "Available for fostering",
  },
  {
    name: "has_special_needs",
    label: "Has special needs",
  },
];

let removedImages = [];

const PetFormPage = () => {
  const [formDependencies, setFormDependencies] = useState({
    genderOptions: [],
    typeOptions: [],
    statusOptions: [],
  });

  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);
  const [shouldRedirectToPetView, setShouldRedirectToPetView] = useState(false);
  const [selectedProfileIndex, setSelectedProfileIndex] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    gender: 1,
    type: 1,
    description: "",
    aggression_level: 0,
    special_needs_details: "",
    birth_date: new Date().toISOString().substr(0, 10),
    admission_date: new Date().toISOString().substr(0, 10),
    adoption_fees: 0,
    length: 0,
    weight: 0,
    height: 0,
    image: "",
    images: [],
    index: 0,
    good_with_kids: 0,
    good_with_other_pets: 0,
    available_for_fostering: 0,
    has_special_needs: 0,
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_pet_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_pet_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        setFormDependencies({
          genderOptions: res.genderOptions,
          typeOptions: res.typeOptions,
          statusOptions: res.statusOptions,
        });
        if (res.data) {
          setFormData(res.data);
          setSelectedProfileIndex(res.data.index);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSelectProfile = (index) => {
    setSelectedProfileIndex(index);
    setFormData((prevFormData) => ({
      ...prevFormData,
      index: index,
      image: formData.images[index],
    }));
  };

  useEffect(() => {
    console.log(selectedProfileIndex);
  }, [selectedProfileIndex]);

  const handleInputChange = (inputName, checked, value) => {
    let updatedValue = value;

    if (checked !== undefined) {
      updatedValue = checked ? 1 : 0;
    }

    if (inputName === "aggression_level") {
      updatedValue = parseFloat(updatedValue);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [inputName]: updatedValue,
    }));

    if (validationErrors[inputName]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [inputName]: undefined,
      }));
    }
  };

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    setFormData((prevFormData) => ({
      ...prevFormData,
      images: [...prevFormData.images, ...files],
      index: selectedProfileIndex,
      image: formData.images[selectedProfileIndex],
    }));
  };

  useEffect(() => {
    console.log(formData);
    for (let i = 0; i < formData.images.length; i++) {
      formData[`images[${i}]`] = formData.images[i];
    }
  }, [formData]);

  const handleRemoveImage = (index) => {
    removedImages.push(formData.images[index].id);
    setFormData((prevFormData) => {
      const newImages = prevFormData.images.slice();
      newImages.splice(index, 1);

      const newIndex =
        index === selectedProfileIndex ? 0 : selectedProfileIndex;

      delete formData[`images[${index}]`];

      return {
        ...prevFormData,
        image: newImages.length > 0 ? newImages[newIndex] : "",
        images: newImages,
        index: newIndex,
        removedImages: removedImages,
      };
    });
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_shop_pets(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_shop_pets(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/pets/list"} />;
  } else if (shouldRedirectToPetView) {
    return <Navigate to={"/admin/pets/view/" + id} />;
  }

  return (
    <div className="page-content py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Pet" : "Add Pet"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/pets/list"}>
            Pets
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? formData.name : "New Pet"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <span className="product-info-title">PET INFO</span>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Images</label>
              <ImageUploader
                images={formData.images}
                onFileInputChange={handleFileInputChange}
                onRemoveImage={handleRemoveImage}
                onSelectProfile={handleSelectProfile}
                selectedProfileIndex={selectedProfileIndex}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleTextInputChange}
                placeholder={"Pet Name"}
              />
              {validationErrors.name && (
                <span className="error-text text-danger">
                  {validationErrors.name[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container">
              <label className="fw-semibold">Pet Gender</label>
              <SelectBox
                c_name={"gender"}
                c_options={formDependencies.genderOptions}
                c_placeholder={"Gender"}
                c_value={formData.gender}
                state_setter={(selectedValue) => {
                  handleInputChange("gender", undefined, selectedValue);
                }}
              />
            </div>

            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Pet Type</label>
              <SelectBox
                c_name={"type"}
                c_options={formDependencies.typeOptions}
                c_placeholder={"Type"}
                c_value={formData.type}
                state_setter={(selectedValue) => {
                  handleInputChange("type", undefined, selectedValue);
                }}
              />
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column"></div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Height</label>
              <input
                type="number"
                name="height"
                value={parseFloat(formData.height)}
                onChange={handleTextInputChange}
                placeholder={"Pet Height"}
              />
              {validationErrors.height && (
                <span className="error-text text-danger">
                  {validationErrors.height[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Weight</label>
              <input
                type="number"
                name="weight"
                value={parseFloat(formData.weight)}
                onChange={handleTextInputChange}
                placeholder={"Pet Weight"}
              />
              {validationErrors.weight && (
                <span className="error-text text-danger">
                  {validationErrors.weight[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Length</label>
              <input
                type="number"
                name="length"
                value={parseFloat(formData.length)}
                onChange={handleTextInputChange}
                placeholder={"Pet Length"}
              />
              {validationErrors.length && (
                <span className="error-text text-danger">
                  {validationErrors.length[0]}
                </span>
              )}
            </div>
          </div>

          <div className="d-flex flex-md-row flex-column"></div>

          <div className="d-flex flex-md-row flex-column">
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Birth date</label>
              <input
                type="date"
                name="birth_date"
                value={formData.birth_date}
                onChange={handleTextInputChange}
              />
              {validationErrors.birth_date && (
                <span className="error-text text-danger">
                  {validationErrors.birth_date[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Admission date</label>
              <input
                type="date"
                name="admission_date"
                value={formData.admission_date}
                onChange={handleTextInputChange}
              />
              {validationErrors.admission_date && (
                <span className="error-text text-danger">
                  {validationErrors.admission_date[0]}
                </span>
              )}
            </div>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
              <label className="fw-semibold">Adoption fees</label>
              <input
                type="number"
                name="adoption_fees"
                value={formData.adoption_fees}
                onChange={handleTextInputChange}
                placeholder="Adoption Fees"
              />
              {validationErrors.adoption_fees && (
                <span className="error-text text-danger">
                  {validationErrors.adoption_fees[0]}
                </span>
              )}
            </div>
          </div>

          <div className="row pr-5">
            <div className="col-12 mb-5">
              <label className="fw-semibold">Description</label>
              <TextEditor
                value={formData.description}
                onChange={(content) => {
                  const descriptionWithoutTags = content
                    .replace(/<p>/g, "")
                    .replace(/<\/p>/g, "");
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    description: descriptionWithoutTags,
                  }));
                }}
                placeholder="Relevant Pet Notes"
              />
            </div>
            <div className="d-md-flex flex-row gap-3">
              {flagFields.map((field, index) => (
                <PawCheckbox
                  key={index}
                  field={field}
                  handleInputChange={handleInputChange}
                  isChecked={formData[field.name]}
                />
              ))}
            </div>
            <div className="col-lg-4 col-md-6 col-12 mt-5">
              <div className="d-flex flex-column gap-1 input-container">
                <label className="fw-semibold">Pet Status</label>
                <SelectBox
                  c_name={"status"}
                  c_options={formDependencies.statusOptions}
                  c_placeholder={"Status"}
                  c_value={formData.status}
                  state_setter={(selectedValue) => {
                    handleInputChange("status", undefined, selectedValue);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="d-flex gap-2 flex-column">
                <div className="col-12 mt-5">
                  <label className="fw-semibold">Aggression level</label>
                  <input
                    type="range"
                    name="aggression_level"
                    min="0"
                    max="10"
                    value={formData.aggression_level}
                    onChange={(event) =>
                      handleInputChange(
                        "aggression_level",
                        undefined,
                        event.target.value
                      )
                    }
                  />
                  <span>{formData.aggression_level}</span>
                  {validationErrors.aggression_level && (
                    <span className="error-text text-danger">
                      {validationErrors.aggression_level[0]}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 d-flex align-items-start mt-5">
              {formData.has_special_needs ? (
                <div className={"flex-grow-1"}>
                  <label className="fw-semibold">Special needs details</label>
                  <textarea
                    name="special_needs_details"
                    value={formData.special_needs_details}
                    onChange={handleTextInputChange}
                    placeholder="Special Needs Details"
                  ></textarea>
                </div>
              ) : (
                <div className="col-8"></div>
              )}
            </div>
          </div>
        </div>

        <hr />

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}
        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary p-10-50"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PetFormPage;
