export const searchApi = (searchQuery, type) => {
  let apiEndpoint = "/api/dash/search";

  const SEARCH_API = process.env.REACT_APP_DASHBOARD_API_URL + apiEndpoint;

  return fetch(`${SEARCH_API}?query=${searchQuery}&type=${type}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
