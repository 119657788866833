import React, { useState, useEffect } from "react";
import PersonalInfo from "./PersonalInfo";
import { getSettingsInfo } from "../../../services/settings.js";

const AdminSettingsForm = () => {
  const [data, setData] = useState({});

  const [formData, setFormData] = useState({
    representerInfo: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number_cc: "",
      phone_number: "",
      picture: null,
      government_id: null,
      password: "",
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  const setInfoData = (data) => {
    const {
      first_name,
      last_name,
      email,
      phone_number_cc,
      phone_number,
      picture,
      government_id,
      password,
    } = data;

    setFormData({
      representerInfo: {
        first_name,
        last_name,
        email,
        phone_number_cc,
        phone_number,
        picture: picture ? picture : null,
        government_id: government_id ? government_id : null,
        password,
      },
    });
  };

  useEffect(() => {
    if (isLoading) {
      getSettingsInfo().then((response) => {
        let data = response.info ? response.info : {};
        setData(data);
        setIsLoading(false);
      });
    }

    if (!isLoading && data && data.shop) {
      setInfoData(data);
    }
  }, [data]);

  return (
    <div className="py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <PersonalInfo formData={formData} setFormData={setFormData} />
    </div>
  );
};

export default AdminSettingsForm;
