import { Checkbox } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';

const BusinessCategory = ({registrationData, setRegistrationData, updateTimeLine}) => {

    const [saveTypeSuccessful, setSaveTypeSuccessful] = useState(false)

    const [errors, setErrors] = useState({
        businessCategory: ''
    });

    const toggleIsProductProvider = () => {
        setRegistrationData({
          ...registrationData,
          businessCategory: {
            ...registrationData.businessCategory,
            isProductProvider: !registrationData.businessCategory.isProductProvider,
            isServiceProvider: false,
            isAdoptionShelter: false,
          },
        });
    }

    const toggleIsServiceProvider = () => {
        setRegistrationData({
          ...registrationData,
          businessCategory: {
            ...registrationData.businessCategory,
            isServiceProvider: !registrationData.businessCategory.isServiceProvider,
            isProductProvider: false,
            isAdoptionShelter: false,
          },
        });
    }

    const toggleHasAdoptions = () => {
        setRegistrationData({
          ...registrationData,
          businessCategory: {
            ...registrationData.businessCategory,
            isAdoptionShelter: !registrationData.businessCategory.isAdoptionShelter,
            isProductProvider: false,
            isServiceProvider: false,
          },
        });
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const validate = () => {
        let isValid = true;
        let errors = {
            businessCategory: ''
        };
    
        if (!registrationData.businessCategory.isProductProvider && !registrationData.businessCategory.isServiceProvider && !registrationData.businessCategory.isAdoptionShelter) {
            errors.businessCategory = 'Please select at least one business category.';
            isValid = false;
        }
    
        setErrors(errors);
        return isValid;
    };
    

    const handleNext = (e) => {
        e.preventDefault();
        if (validate()) {
            updateTimeLine(1);
            setSaveTypeSuccessful(true);
        }
    };

    useEffect(() => {
      const formContainer = document.getElementById("form-container");
      if (formContainer) {
        formContainer.scrollTop = 0;
      }
    }, []);

    
    return (
        <form onSubmit={handleNext}>
        <div className="center-content">
            <div className={"signup-form p-3"}>
                <h2 className="mb-2 fw-bold">Choose your business category</h2>
                <p className="form-help">you can choose more than one category to fit your business.</p>

                {errors.businessCategory && (
                    <div className="alert alert-danger mt-2" role="alert">
                        {errors.businessCategory}
                    </div>
                )}

                <div className={"check-input-container" + (registrationData.businessCategory.isProductProvider ? ' checkbox-selected' : '')}>

                    <Checkbox {...label} checked={registrationData.businessCategory.isProductProvider} size='large' sx={{
                        color: "#CFCFCF",
                        '&.Mui-checked': {
                            color: "#FD7D64",
                        },
                    }}
                        onChange={toggleIsProductProvider}
                    />

                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        <p className='input-title'>Product Provider</p>
                        <p className='input-info'>( Food , pets products ....... )</p>
                    </label>
                </div>

                <div className={"check-input-container" + (registrationData.businessCategory.isServiceProvider ? ' checkbox-selected' : '')}>

                    <Checkbox {...label} checked={registrationData.businessCategory.isServiceProvider} size='large' sx={{
                            color: "#CFCFCF",
                            '&.Mui-checked': {
                                color: "#FD7D64",
                            },
                        }}
                            onChange={toggleIsServiceProvider}
                        />

                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            <p className='input-title'>Service Provider</p>
                            <p className='input-info'>( Grooming, Training, Hotel & Daycare , ....... )</p>
                        </label>
                    </div>

                <div className={"check-input-container" + (registrationData.businessCategory.isAdoptionShelter ? ' checkbox-selected' : '')}>

                    <Checkbox {...label} checked={registrationData.businessCategory.isAdoptionShelter} size='large' sx={{
                        color: "#CFCFCF",
                        '&.Mui-checked': {
                            color: "#FD7D64",
                        },
                    }}
                        onChange={toggleHasAdoptions}
                    />

                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        <p className='input-title'>Adoption Shelter</p>
                        <p className='input-info'>( Cats , Dogs , Fish , Bird , ....... )</p>
                    </label>
                </div>

                <button type="submit" className="btn btn-primary py-2 mt-4 fw-bold w-100">Next</button>
            </div>
        </div>
        </form>
    );
}

export default BusinessCategory;
