import React from "react";

const Profile = (props) => {
    return (
        <div className="py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
            yow
        </div>
    )
}

export default Profile;