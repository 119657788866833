import Select from "react-select";
import React, {useEffect, useState} from "react";

const SelectBox = ({c_name, c_placeholder, c_options, c_value, state_setter}) => {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(getDefaultOption([], c_value))

    useEffect(() => {
        setOptions(c_options);
        setSelected(getDefaultOption(c_options, c_value))
    }, [c_options]);

    const handleOnChange = (newlySelectedOption) => {
        setSelected(newlySelectedOption)
        state_setter(newlySelectedOption.value)
    }

    return <Select
        name={c_name}
        options={options}
        placeholder={c_placeholder}
        classNamePrefix="select"
        onChange={handleOnChange}
        value={selected}
    />
}

const getDefaultOption = (options, defaultValue) => {
    if (options.length === 0) {
        return {label: '', value: (defaultValue !== undefined ? defaultValue : '')}
    }

    let selectedOptionsByValue = options.filter(option => (option.value === defaultValue))
    let selectedOptionsByFlag = options.filter((option) => (option.active === true || option.selected === true))

    if (selectedOptionsByValue.length > 0) {
        return selectedOptionsByValue[0]
    }

    if (selectedOptionsByFlag.length > 0) {
        return selectedOptionsByValue[0]
    }

    return options[0]
}


SelectBox.defaultProps = {
    c_value: undefined
}

export default SelectBox;