import React, { useEffect, useState } from "react";
import {
  fetch_accounts,
  delete_account,
  export_accounts,
} from "../../services/accounts";
import ItemListingTable, {
  getTableUpdateCallback,
} from "./common/ItemListingTable";
import { useSearchParams } from "react-router-dom";
import { notify_promise } from "../../services/utils/toasts";
import ListingPageControls from "./common/ListingPageControls";

const AccountListingPage = ({ pageState, setPageState }) => {
  const [accounts, setAccounts] = useState([]);

  const [fields, setFields] = useState([]);
  const [pagination, setPagination] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get("search"));
  const [latestSearchQuery, setLatestSearchQuery] = useState(
    searchParams.get("search")
  );

  const [perPage, setPerPage] = useState(10);

  const apiCall = [
    fetch_accounts,
    { page: 0, search: search ? search : "", is_shop: true, perPage: perPage },
  ];

  const handleDelete = (id) => {
    notify_promise(
      new Promise((resolve, reject) => {
        delete_account([id])
          .then((res) => {
            setAccounts((prevPets) => {
              return prevPets.filter((p) => {
                return p.id !== id;
              });
            });
            resolve(res);
          })
          .catch(reject);
      })
    );
  };

  useEffect(() => {
    setSearch(searchParams.get("search"));
  }, [searchParams.get("search")]);

  useEffect(() => {
    setLatestSearchQuery(search);
  }, [search]);

  useEffect(() => {
    updateTableInfo(0);
  }, [latestSearchQuery, perPage]);

  const updateTableInfo = getTableUpdateCallback({
    apiCall: apiCall,
    dataSetter: setAccounts,
    paginationSetter: setPagination,
    fieldSetter: setFields,
    buttons: [
      {
        template: "edit",
        getRedirectURL: (id) => {
          return "/admin/accounts/edit/:id";
        },
      },
      {
        template: "delete",
        onClick: (id) => {
          handleDelete(id);
        },
      },
    ],
  });

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <ListingPageControls
        type="account"
        addOnly={true}
        resultType="account"
        setSearch={setSearchParams}
        noExport={true}
        addNewLink="/admin/accounts/add"
      />

      <ItemListingTable
        fields={fields}
        data={accounts}
        pagination={pagination}
        updateTableInfo={updateTableInfo}
        setPerPage={setPerPage}
        perPage={perPage}
      />
    </div>
  );
};

export default AccountListingPage;
