import { parsePhoneNumber } from "react-phone-number-input";

export const handlePhoneNumberChange = (
  value,
  setPhoneNumber,
  setPhoneNumberCC
) => {
  if (typeof value === "string") {
    const digitCount = (value.match(/\d/g) || []).length;

    if (digitCount > 1) {
      const parsedPhoneNumber = parsePhoneNumber(value);

      if (parsedPhoneNumber) {
        setPhoneNumber(value);
        const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
        setPhoneNumberCC(phoneNumberCc);
      } else {
        setPhoneNumber("");
        setPhoneNumberCC("");
      }
    } else {
      setPhoneNumber("");
      setPhoneNumberCC("");
    }
  } else {
    setPhoneNumber("");
    setPhoneNumberCC("");
  }
};

export const handleLandlineChange = (value, setLandline, setLandlineCC) => {
  if (typeof value === "string") {
    const digitCount = (value.match(/\d/g) || []).length;

    if (digitCount > 1) {
      const parsedPhoneNumber = parsePhoneNumber(value);

      if (parsedPhoneNumber) {
        setLandline(value);
        const phoneNumberCc = parsedPhoneNumber.countryCallingCode;
        setLandlineCC(phoneNumberCc);
      } else {
        setLandline("");
        setLandlineCC("");
      }
    } else {
      setLandline("");
      setLandlineCC("");
    }
  } else {
    setLandline("");
    setLandlineCC("");
  }
};

// export const handleCityChange = (event, locationData, city, setCity, setFilteredAreas, setArea) => {
//     const selectedCity = event.target.value;
//     setCity(selectedCity);
//     const areas = locationData.areas[selectedCity] || [];
//     setFilteredAreas(areas);
//     setArea('');
//   };

export const validate = (
  shopName,
  businessDescription,
  phoneNumber,
  landline,
  licenseName,
  license,
  businessLogo,
  setErrors
) => {
  let isValid = true;
  let errors = {
    shopName: "",
    businessDescription: "",
    phoneNumber: "",
    landline: "",
    licenseName: "",
    license: "",
    businessLogo: "",
  };

  if (!shopName || !shopName.trim()) {
    errors.shopName = "Shop name is required";
    isValid = false;
  }

  if (!businessDescription || !businessDescription.trim()) {
    errors.businessDescription = "Business description is required";
    isValid = false;
  }

  if (!phoneNumber || !phoneNumber.trim()) {
    errors.phoneNumber = "Phone number is required";
    isValid = false;
  }

  if (!landline || !landline.trim()) {
    errors.landline = "Landline is required";
    isValid = false;
  }

  if (!licenseName || !licenseName.trim()) {
    errors.licenseName = "licenseName is required";
    isValid = false;
  }

  if (license && license.type !== "application/pdf") {
    errors.license = "Invalid file type. Please upload a PDF document";
    isValid = false;
  }

  if (businessLogo && !/\.(jpe?g|png|gif)$/i.test(businessLogo.name)) {
    errors.businessLogo =
      "Invalid image type for business logo. Please upload a JPEG, PNG, or GIF file.";
    isValid = false;
  }

  setErrors(errors);
  return isValid;
};
