import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Link, Navigate } from "react-router-dom";
import { sendOtp, verifyOtp } from "../../services/otpService";
import { useNavigate } from "react-router-dom";

const VerifyNumber = (props) => {
  const [otp, setOtp] = useState("");

  // const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [sendOtpError, setSendOtpError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const formContainer = document.getElementById("form-container");
    if (formContainer) {
      formContainer.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    let phoneNumber;

    if (props.registrationData) {
      const { representerInfo } = props.registrationData;
      phoneNumber = representerInfo.phone_number;
    } else if (props.phoneData) {
      phoneNumber = props.phoneData.phone_number;
    }

    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    } else {
      setError("Phone number not valid");
    }
  }, [props.phoneData, props.registrationData]);

  const handleSendOtp = (e) => {
    e.preventDefault();
    setIsSendingOtp(true);
    setSendOtpError("");

    sendOtp(phoneNumber)
      .then((response) => {
        if (response.status === "ok") {
          setIsSendingOtp(false);
          setError("");
        } else {
          setIsSendingOtp(false);
          setSendOtpError("Failed to send OTP. Please try again.");
        }
      })
      .catch((error) => {
        setIsSendingOtp(false);
        setSendOtpError("Failed to send OTP. Please try again.");
      });
  };

  const handleOtpVerification = (e) => {
    e.preventDefault();
    setError("");
    console.log("Verification");

    verifyOtp(phoneNumber, otp)
      .then((response) => {
        if (response.status === "ok") {
          setIsSendingOtp(false);
          setIsValid(true);
          if (props.path === "/signupData/businessInfo")
            props.updateTimeLine(3);
        } else {
          console.log("error");
          setIsValid(false);
          setIsSendingOtp(false);
          setError("OTP verification failed. Please try again.");
        }
      })
      .catch((error) => {
        console.log("error");
        setIsValid(false);
        setIsSendingOtp(false);
        setError("OTP verification failed. Please try again.");
      });
  };

  useEffect(() => {
    if (isValid) {
      navigate(props.path);
    }
  }, [isValid, props.path, navigate]);

  return (
    <div className="center-content">
      <form className={props.class} method="POST">
        <h1 className="mb-1 text-center">Verify phone number</h1>
        {error && <div className="alert alert-danger mt-2">{error}</div>}
        <p className="form-help mb-5">
          enter the code sent to{" "}
          <span className="phone-number">{phoneNumber}</span>
        </p>

        <div className="otp mb-5 position-relative">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="tel"
            renderInput={(props) => <input {...props} />}
            placeholder="____"
          />
        </div>
        <div>
          {props.path === "/signupData/businessInfo" && (
            <button
              onClick={handleOtpVerification}
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold"
            >
              {props.inputText}
            </button>
          )}
          {props.path === "/resetPassword/newPassword" && (
            <button
              onClick={handleOtpVerification}
              type="submit"
              className="btn btn-primary w-100 py-2 fw-bold"
            >
              {props.inputText}
            </button>
          )}

          {sendOtpError && (
            <div className="alert alert-danger mt-2">{sendOtpError}</div>
          )}
          <button
            onClick={handleSendOtp}
            disabled={isSendingOtp}
            className="resend-btn btn btn-primary w-100 py-2 fw-bold"
          >
            {isSendingOtp ? "Sending..." : "Resend Code"}
          </button>

          <br />
          <Link to={props.path}>
            <button
              className="resend-btn btn btn-primary w-100 py-2 fw-bold"
              onClick={() => {
                if (props.path === "/signupData/businessInfo")
                  props.updateTimeLine(3);
              }}
            >
              Skip
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default VerifyNumber;
