import PhoneInput from "react-phone-number-input";
import React from "react";
import SingleFileUploader from "../SingleFileUploader";

const DynamicInput = ({fieldValue, fieldData, handleChange, handleAttachmentChange, containerClass}) => {
    if (containerClass === undefined) {
        containerClass = ""
    }

    const label = (fieldData.label === "" || fieldData.label === undefined) ? "" : <label className={"fw-semibold"}>{fieldData.label}</label>
    return <div className={containerClass}>
        {label}
        <Input fieldValue={fieldValue} fieldData={fieldData} handleChange={handleChange} handleAttachmentChange={handleAttachmentChange}/>
    </div>
};

const Input = ({fieldValue, fieldData, handleChange, handleAttachmentChange}) => {
    const {label, value, type, required, name, placeholder, options} = fieldData;
    const handleInputChange = (e) => {
        handleChange(e.target.name, e.target.value)
    }

    switch (type) {
      case "text":
      case "email":
        return (
          <input
            type={type}
            name={name}
            value={fieldValue}
            required={required}
            onChange={handleInputChange}
            placeholder={placeholder}
          />
        );
      case "password":
        return (
          <input
            type={type}
            name={name}
            value={fieldValue}
            required={required}
            onChange={handleInputChange}
            placeholder={placeholder}
            autoComplete="new-password"
          />
        );
      case "phone":
        return (
          <PhoneInput
            defaultCountry="AE"
            international
            withCountryCallingCode
            value={fieldValue || ""}
            onChange={(val) => {
              handleChange(name, val);
            }}
            name={name}
          />
        );
      case "textarea":
        return (
          <textarea
            value={value || ""}
            placeholder={placeholder}
            onChange={handleInputChange}
            name={name}
          />
        );
      case "select":
        return (
          <select
            placeholder={placeholder}
            name={name}
            value={value}
            required={required}
            onChange={handleInputChange}
          >
            {options.map((option, index) => (
              <option value={option.value} key={index}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case "image":
        return (
          <SingleFileUploader
            options={{
              name: name,
              currentPhoto: value,
              onPhotoChange: handleAttachmentChange,
            }}
            type="image"
          />
        );
      case "pdf":
        return (
          <SingleFileUploader
            options={{
              name: name,
              currentPhoto: value,
              onPhotoChange: handleAttachmentChange,
            }}
            type="pdf"
          />
        );
      default:
        return null;
    }
}

export default DynamicInput