import axios from "axios";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/search/top";
const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/shelter/pets/";
export const IMPORT_API = API_HOST + "import";

export const fetch_shop_pets = (options) => {
  const encodedQueryParams = new URLSearchParams(options);

  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_shop_pet = (id) => {
  return fetch(API_HOST + id, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_pet_edit_form = (id) => {
  return fetch(API_HOST + id + "/edit", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_pet_create_form = () => {
  return fetch(API_HOST + "create", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export async function pet_import_uploader(file, progressCallback) {
  const formData = new FormData();
  formData.append("import_file", file);

  await axios.post(IMPORT_API, formData, {
    withCredentials: true,
    onUploadProgress: (progressEvent) => {
      const progress = progressEvent.loaded / progressEvent.total;
      progressCallback(progress);
    },
  });
}

export const export_shop_pets = () => {
  return fetch(API_HOST + "export", {
    method: "POST",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const add_shop_pets = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("image", data.image);
  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

const update_pet_documents = (formData) => {
  return fetch(API_HOST + "register-documents", {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
};

export const edit_shop_pets = (id, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  if (data.image instanceof File) {
    formData.append("image", data.image);
  }
  return fetch(`${API_HOST}${id}?_method=PUT`, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

export const delete_shop_pets = (ids) => {
  let idCsv = ids.join(",");
  return fetch(`${API_HOST}${idCsv}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_shop_pets = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=pet`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const generate_pet_invoice = (petId, petOwnerId, petOwnerEmail) => {
  return fetch(API_HOST + "invoice", {
    method: "POST",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      pet_id: petId,
      pet_owner_id: petOwnerId,
      pet_owner_email: petOwnerEmail,
    }),
  }).then((res) => res.json());
};
