import React from "react";
import NewIcon from "../../../resources/themes/dashboard-v1/icons/new.svg";
import {Link} from "react-router-dom";

const OrderEntry = ({ entry }) => {
  const { id, count_products, total_text, created_at } = entry;

  const createdAtFormatted = new Date(created_at).toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <Link to={"/admin/orders"}>
      <div className="col-12 d-flex align-items-center adoption-entry py-2">
        <div className="col-9">
          <div>
            {count_products
              ? count_products + (count_products > 1 ? " Products" : " Product")
              : ""}
          </div>
          <p className="id">Order ID: #{id}</p>
          <p className="id text-danger fw-bold">{total_text}</p>
          <p className="date">{createdAtFormatted}</p>
        </div>
        <div className="col-3 d-flex align-items-center justify-content-center p-0">
          <img src={NewIcon} alt="" />
        </div>
      </div>
    </Link>
  );
};

export default OrderEntry;
