import React, { useState } from 'react';
import logoWhite from '../../resources/themes/dashboard-v1/img/logo-white.png';
import iconEnvelope from '../../resources/themes/dashboard-v1/icons/envelope.svg'
import ResetPassword from './ResetPassword';
import VerifyNumber from './VerifyNumber';
import NewPassword from './NewPassword';
import { Route, Routes } from 'react-router-dom';

const ResetPasswordPage = () => {
    const [phoneData, setPhoneData] = useState({
        phone_number: '',
        phone_number_cc: '',
    });
    
    return (
        <section className="reset-password-page login-section container-fluid">
            <div className="row full-screen-height">
                <div className="col-md-6 account-left-col flex-container-center full-height">
                    <div className="bg-doodles flex-container-center">
                        <div className="text-center full-width logo-container">
                            <img src={logoWhite} alt="Logo" className="logo" />
                        </div>
                        <div className="full-width">
                            <Routes>
                                <Route  path={'/'} element={<ResetPassword class={"mobile-only p-3 bg-white"} phoneData={phoneData} setPhoneData={setPhoneData}/>}></Route>
                                <Route  path={'/otp'} element={<VerifyNumber class={"mobile-only p-3 bg-white"} inputText={"Continue"} path={'/resetPassword/newPassword'} phoneData={phoneData} setPhoneData={setPhoneData}/>}></Route>
                                <Route  path={'/newPassword'} element={<NewPassword class={"mobile-only p-3 bg-white"} phoneData={phoneData} setPhoneData={setPhoneData}/>}></Route>
                            </Routes>
                        </div>
                        <div className="bg-footer flex-container-center">
                            <p className="text-white">© Pawpaws 2023</p>
                            <a className="link-plain text-white email-link" href="mailto:help@pawpaws.com" target="_blank">
                                <img src={iconEnvelope} alt="envelope" />
                                <span>help@pawpaws.com</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 desktop-only">
                    <div className="full-height flex-container-center flex-column">
                        <div className="flex-container-column flex-container-center">
                            <Routes>
                                <Route path={'/'} element={<ResetPassword class={"login-form p-3"} phoneData={phoneData} setPhoneData={setPhoneData} />}></Route>
                                <Route path={'/otp'} element={<VerifyNumber class={"login-form p-3"} inputText={"Continue"} path={'/resetPassword/newPassword'} phoneData={phoneData} setPhoneData={setPhoneData}/>}></Route>
                                <Route path={'/newPassword'} element={<NewPassword class={"login-form p-3"} phoneData={phoneData} setPhoneData={setPhoneData} />}></Route>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ResetPasswordPage;
