import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateRegistrationData } from "../../../services/settings.js";
import { notify_promise, notify_error } from "../../../services/utils/toasts";

const SettingsFormTabs = ({ forms, defaultIndex, errors, setErrors, formData, setFormData, confirmPassword, setConfirmPassword }) => {
  const navigate = useNavigate();
  const [formSubmitError, setFormSubmitError] = useState(null);

  const validateForm = (formData) => {
    const validationErrors = {
      first_name: "",
      last_name: "",
      email: "",
      phone_number_cc: "",
      phone_number: "",
      picture: "",
      government_id: "",
      password: "",
    };

    if (!formData.representerInfo) {
      formData.representerInfo = {};
    }

    if (!formData.representerInfo.first_name) {
      validationErrors.first_name = "First Name is required";
    }
    if (!formData.representerInfo.last_name) {
      validationErrors.last_name = "Last Name is required";
    }
    if (!formData.representerInfo.email) {
      validationErrors.email = "Email is required";
    }
    if (!formData.representerInfo.phone_number) {
      validationErrors.phone_number = "Phone Number is required";
    }
    if (!isPasswordValid(formData.representerInfo.password)) {
      validationErrors.password =
        "Password should have minimium 8 chars, 1 capital letter, and 1 symbol/number.";
    }
    if (
      confirmPassword &&
      formData.representerInfo.password !== confirmPassword
    ) {
      validationErrors.password = "Confirm password correctly";
    }

    return validationErrors;
  };

  const isPasswordValid = (password) => {
    const minLength = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/;
    const hasUpperCase = /[A-Z]/;
    const hasNumberOrSpecialChar = /[\d!@#$%&]/;

    return (
      minLength.test(password) &&
      hasUpperCase.test(password) &&
      hasNumberOrSpecialChar.test(password)
    );
  };

  const handleFormSubmit = async () => {
    setFormSubmitError(null);

    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    if (errors.length > 0) {
      return;
    }

    try {
      const response = await updateRegistrationData(formData);
      if (response.status === "ok") {
        console.log("success");
        notify_promise(Promise.resolve(response));
      } else {
        setFormSubmitError("Can't Update");
        notify_error("Can't Update");
        notify_promise(Promise.reject(response));
      }
    } catch (error) {
      setFormSubmitError("Can't Update");
      notify_error("Can't Update");
    }
  };

  return (
    <div className="form-renders">
      <div className="d-flex justify-content-between">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {forms.map((form, index) => {
            const isActive = index === defaultIndex;
            const activeClass = isActive ? "active" : "";

            return (
              <li
                key={index}
                className={`nav-item ${activeClass}`}
                role="presentation"
              >
                <button
                  className={"nav-link " + activeClass}
                  id={form.slug + "-tab"}
                  data-bs-toggle="tab"
                  data-bs-target={"#" + form.slug}
                  type="button"
                  role="tab"
                  aria-controls={form.slug}
                  aria-selected={isActive}
                  onClick={() => {
                    navigate(`/admin/settings/${form.slug}`);
                  }}
                >
                  {form.label}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers px-4">
          <button
            className={"btn btn-primary pl-5 pr-5"}
            onClick={handleFormSubmit}
          >
            Save
          </button>
        </div>
      </div>
      <div className="tab-content" id="myTabContent">
        {forms.map((form, index) => {
          const isActive = index === defaultIndex;
          const activeClass = isActive ? " show active" : "";
          const FormComponent = form.component;
          return (
            <div
              key={index}
              className={"tab-pane fade" + activeClass}
              id={form.slug}
              role="tabpanel"
              aria-labelledby={form.slug + "-tab"}
            >
              <FormComponent
                formData={formData}
                setFormData={setFormData}
                errors={errors}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SettingsFormTabs;
