import React from 'react';
import logoWhite from '../../resources/themes/dashboard-v1/img/logo-white.png';
import iconEnvelope from '../../resources/themes/dashboard-v1/icons/envelope.svg'
import SignUp from './signUp';
import { Link } from 'react-router-dom';

const SignupPage = () => {
    return (
        <section className="signup-section login-section container-fluid">
            <div className="row full-screen-height">
                <div className="col-md-6 account-left-col flex-container-center full-height">
                    <div className="bg-doodles flex-container-center">
                        <div className="text-center full-width logo-container">
                            <img src={logoWhite} alt="Logo" className="logo" />
                        </div>

                        <div className="full-width px-md-3 px-0">
                            <SignUp class={"mobile-only signup-form p-3 bg-white"}/>
                        </div>
                        <div className="bg-footer flex-container-center">
                            <p className="text-white">© Pawpaws 2023</p>
                            <a className="link-plain text-white email-link" href="mailto:help@pawpaws.com" target="_blank" rel="noreferrer" >
                                <img src={iconEnvelope} alt="envelope" />
                                <span>help@pawpaws.com</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 desktop-only">
                    <div className="text-right full-width p-3 absolute-top">
                        <Link to={'/login'}><button className="btn btn-primary btn-padded">Log in</button></Link>
                    </div>
                    <div className="full-height flex-container-center flex-column">
                        <div className="flex-container-column flex-container-center">
                            <SignUp class={"signup-form p-3"} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignupPage;
