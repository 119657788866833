const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/clinic/service-categories/";

const EXPORT_API = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/clinic/export/categories";

export const fetch_service_categories = (page) => {
  const queryParams = new URLSearchParams({
    page: page,
  });

  return fetch(`${API_HOST}?${queryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const export_service_categories = () => {
  return fetch(EXPORT_API, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

