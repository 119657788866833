const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/stats";

export const fetch_stats = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const refresh_stats = () => {
  return fetch(API_HOST + "/refresh", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_stat = (title, from, to) => {
  const encodedQueryParams = new URLSearchParams({from, to});
  return fetch(`${API_HOST}/${title}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
