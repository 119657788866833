import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  add_banners,
  edit_banners,
  fetch_banner_edit_form,
  fetch_banner_create_form,
} from "../../../services/admin/banners";
import Select from "react-select";
import SingleFileUploader from "../../SingleFileUploader";
import BannerPreviewBox from "./BannerPreviewBox";
import { Link, Navigate, useParams } from "react-router-dom";

const HomeBannersFormPage = () => {
  const { id } = useParams();

  const [shouldRedirectToIndex, setShouldRedirectToIndex] = useState(false);

  const [formData, setFormData] = useState({
    text: "",
    background_color: "#ffffff",
    background_image: "",
    button_text: "",
    button_text_color: "#ffffff",
    button_bg_color: "#000000",
    type: "",
    model_id: "",
  });

  const [validationErrors, setValidationErrors] = useState({});

  const [types, setTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [petProfiles, setPetProfiles] = useState([]);
  const [shops, setShops] = useState([]);

  let options;

  switch (formData.type) {
    case "product":
      options = Object.entries(products).map(([value, label]) => ({
        value: parseInt(value),
        label: "#" + value + " - "+label,
      }));
      break;
    case "service":
      options = Object.entries(services).map(([value, label]) => ({
        value: parseInt(value),
        label: "#" + value + " - "+label,
      }));
      break;
    case "pet":
      options = Object.entries(petProfiles).map(([value, label]) => ({
        value: parseInt(value),
        label: "#" + value + " - " + label,
      }));
      break;
    case "shop":
      options = Object.entries(shops).map(([value, label]) => ({
        value: parseInt(value),
        label: "#" + value + " - " + label,
      }));
      break;
    default:
      options = [];
  }

  useEffect(() => {
    console.log(formData);
  }, [petProfiles, shops, services, products, formData]);

  useEffect(() => {
    let formDepCallback = () => {
      return fetch_banner_create_form();
    };
    if (id !== undefined) {
      formDepCallback = () => {
        return fetch_banner_edit_form(id);
      };
    }

    formDepCallback().then((res) => {
      if (res.status === "ok") {
        if (res.data) {
          setFormData(res.data);
        }
        if (res.types) {
          setTypes(res.types);
        }
        if (res.petProfiles) {
          setPetProfiles(res.petProfiles);
        }
        if (res.products) {
          setProducts(res.products);
        }
        if (res.services) {
          setServices(res.services);
        }
        if (res.shops) {
          setShops(res.shops);
        }
      }
    });
  }, []);

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    
    if (validationErrors[name]) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const handleSelectInputChange = (name, selected) => {
    handleTextInputChange({
      target: {
        name: name,
        value: selected.value,
      },
    });
  };

  const handleFileInputChange = (fieldValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      background_image: fieldValue,
    }));
  };

  const handleSave = () => {
    console.log(formData);
    setValidationErrors({});
    if (id !== undefined) {
      edit_banners(id, formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    } else {
      add_banners(formData).then((res) => {
        if (res.status === "ok") {
          setShouldRedirectToIndex(true);
        } else if (res.type === "validation") {
          setValidationErrors(res.validations);
        }
      });
    }
  };

  if (shouldRedirectToIndex) {
    return <Navigate to={"/admin/home-banners/list"} />;
  }

  return (
    <div className="page-content py-3 px-4 flex-grow-1 flex-shrink-1 bg-lightgray">
      <div className="mb-4">
        <span className="fw-bold dashboard-title">
          {id !== undefined ? "Edit Banner" : "Add Banner"}
        </span>
        <div className="breadcrumbs path fw-semibold gap-2 d-flex flex-row">
          <Link className={"link"} to={"/admin/"}>
            Dashboard
          </Link>
          <span>&gt;</span>
          <Link className={"link"} to={"/admin/home-banners/list"}>
            Home Banners
          </Link>
          <span>&gt;</span>
          <span>{id !== undefined ? "#" + id : "New Banner"}</span>
        </div>
      </div>

      <div className="inputs-container bg-white">
        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger">
            Please address the errors highlighted below.
          </div>
        )}
        <div className="product-info d-flex flex-column gap-4">
          <div className="row">
            <div className="col-8">
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Banner Type</label>
                  <Select
                    name="type"
                    options={types}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="banner type..."
                    onChange={(selected) => {
                      handleSelectInputChange("type", selected);
                    }}
                    value={types.find(
                      (option) => option.value === formData.type
                    )}
                  />
                </div>
                {formData.type && formData.type !== "webview" && (
                  <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                    <label className="fw-semibold">{`Select ${formData.type}`}</label>
                    <Select
                      name="model_id"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={`Select ${formData.type}...`}
                      onChange={(selected) => {
                        handleSelectInputChange("model_id", selected);
                      }}
                      value={options.find(
                        (option) => option.value === formData.model_id
                      )}
                    />
                  </div>
                )}
                {formData.type === "" ||
                  (formData.type === "webview" && (
                    <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3"></div>
                  ))}
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Banner Text</label>
                  <input
                    type="text"
                    name="text"
                    value={formData.text}
                    onChange={handleTextInputChange}
                    placeholder={"banner text"}
                  />
                  {validationErrors.text && (
                    <span className="error-text text-danger">
                      {validationErrors.text[0]}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Button Text</label>
                  <input
                    type="text"
                    name="button_text"
                    value={formData.button_text}
                    onChange={handleTextInputChange}
                    placeholder={"button text"}
                  />
                </div>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Button Text Color</label>
                  <input
                    type="color"
                    name="button_text_color"
                    value={formData.button_text_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  />
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Button Background Color</label>
                  <input
                    type="color"
                    name="button_bg_color"
                    value={formData.button_bg_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
              </div>
              <div className="d-flex flex-md-row flex-column">
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Background Image</label>
                  <SingleFileUploader
                    options={{
                      name: "background_image",
                      currentPhoto: formData.background_image,
                      onPhotoChange: handleFileInputChange,
                      type: "file",
                      height: "100",
                    }}
                    type="image"
                  />
                </div>
                <div className="d-flex flex-column gap-1 input-container mb-md-4 mb-3">
                  <label className="fw-semibold">Background Color</label>
                  <input
                    type="color"
                    name="background_color"
                    value={formData.background_color}
                    onChange={handleTextInputChange}
                    className="p-0 color"
                  ></input>
                </div>
              </div>
            </div>
            <div className="col-4">
              <BannerPreviewBox formData={formData} />
            </div>
          </div>
        </div>

        {Object.keys(validationErrors).length > 0 && (
          <div className="alert alert-danger mt-3">
            Please address the errors highlighted.
          </div>
        )}

        <div className="d-flex flex-md-row flex-column justify-content-md-end align-items-center gap-3 btn-containers">
          <button className={"btn btn-primary p-10-50"} onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeBannersFormPage;
