const API_HOST = process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/auth/";

export function getSettingsInfo() {
  return fetch(API_HOST + "info", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .catch((error) => console.error(error));
}

export function updateRegistrationData(registrationData) {
  const { representerInfo, shop } = registrationData;

  const formData = new FormData();

  if (representerInfo && representerInfo.government_id) {
    formData.append("government_id", representerInfo.government_id);
  }

  if (representerInfo && representerInfo.picture) {
    formData.append("picture", representerInfo.picture);
  }

  if (shop) {
    if (shop.trade_license) {
      formData.append("license", shop.trade_license);
    }
    if (shop.logo) {
      formData.append("business_logo", shop.logo);
    }
  }

  return fetch(API_HOST + "update-registration", {
    method: "PATCH",
    credentials: "include",
    body: JSON.stringify(registrationData),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === "ok") {
        return update_registration_documents(formData).then(
          (uploadResponse) => {
            if (uploadResponse.status === "ok") {
              return {
                status: "ok",
                failed: "none",
              };
            }
            return {
              status: "error",
              failed: "uploads",
            };
          }
        );
      }

      return {
        status: "error",
        failed: "data",
      };
    })
    .catch((error) => {
      console.error(error);
      return { error };
    });
}

const update_registration_documents = (formData) => {
  return fetch(API_HOST + "register-documents", {
    method: "POST",
    credentials: "include",
    body: formData,
  })
    .then((res) => res.json())
    .catch((error) => {
      console.error(error);
      return { error };
    });
};
