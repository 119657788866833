import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetch_stat } from "../../services/shelters/stats";
import { notify_promise } from "../../services/utils/toasts";
import DynamicChart from "./common/DynamicChart";

const StatsChartViewPage = () => {
  const { stat } = useParams();
  const currentDate = new Date().toISOString().split("T")[0];

  const [data, setData] = useState({});
  const [titleDescriptions, setTitleDescriptions] = useState({});
  const [toDate, setToDate] = useState(currentDate);
  const [fromDate, setFromDate] = useState(() => {
    const tenDaysBefore = new Date();
    tenDaysBefore.setDate(tenDaysBefore.getDate() - 10);
    return tenDaysBefore.toISOString().split("T")[0];
  });

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const getStatistics = () => {
    notify_promise(
      fetch_stat(stat, fromDate, toDate).then((response) => {
        let statData = response.card[0] ? response.card[0] : {};
        setData(statData);
        setTitleDescriptions(response.descriptions);
      })
    );
  };

  useEffect(() => {
    getStatistics();
  }, [fromDate, toDate]);

  return (
    <section className="page-content py-3 px-4 bg-lightgray">
      <div className="row justify-content-between">
        <div className="col-auto">
          <h1 className="dashboard-title">{data.title}</h1>
        </div>
        <div className="col-auto">
          <div className="row justify-content-end">
            <div className="col-auto pe-0 d-flex justify-content-end">
              <input
                type="date"
                className="form-control w-auto"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div className="col-auto px-0 d-flex justify-content-between">
              <p className="m-0 px-2"> _ </p>
              <input
                type="date"
                className="form-control w-auto"
                value={toDate}
                onChange={handleToDateChange}
              />
            </div>
          </div>
        </div>
        
      </div>

      <p className="mt-4">{titleDescriptions[data.key]}</p>
      <div className="d-flex justify-content-center mt-5">
        <DynamicChart
          data={data.chart_data}
          type={data.chart_type}
          width={1000}
          height={600}
          isView={true}
        />
      </div>
    </section>
  );
};

export default StatsChartViewPage;
