import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SettingsFormTabs from "../common/SettingsFormTabs";
import AdminSettingsForm from "./AdminSettingForm";

const AdminSettingsPage = () => {

  const { tabName } = useParams();

  const forms = [
    { label: "Admin Settings", slug: "admin", component: AdminSettingsForm },
  ];

  const defaultActiveIndex = tabName
    ? forms.findIndex((form) => form.slug === tabName)
    : 0;

  return (
    <div className="page-content py-3 px-4 bg-lightgray">
      <SettingsFormTabs forms={forms} defaultIndex={defaultActiveIndex} />
    </div>
  );
};

export default AdminSettingsPage;
