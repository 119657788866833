const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/product-categories";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/search/top";

export const fetch_product_categories = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const delete_product_category = (id) => {
  return fetch(`${API_HOST}/${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_product_categories = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=blog`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_product_category_edit_form = (id) => {
  return fetch(API_HOST + "/" + id + "/edit", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_product_category_create_form = () => {
  return fetch(API_HOST + "/" + "create", {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const edit_product_categories = (id, data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  if (data.image instanceof File) {
    formData.append("image", data.image);
  }
  return fetch(`${API_HOST}/${id}?_method=PUT`, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};

export const add_product_categories = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("image", data.image);
  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: formData,
    headers: {},
  }).then((res) => res.json());
};
