import React, { useEffect } from "react";
import iconClose from "../../../../resources/themes/dashboard-v1/icons/close.svg";

import { Link } from "react-router-dom";

import {
  chatMessageIcon,
  circleXIcon,
  dropdownCaret,
  locationIcon,
  phoneIcon,
} from "../../../../resources/themes/dashboard-v1/icons/icons";

import { notify_promise } from "../../../../services/utils/toasts";

const AdoptionRequestPopup = ({
  adoption
}) => {
  if (adoption === undefined) {
    return (
      <div
        id="adoptionPopup"
        className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
      ></div>
    );
  }

  const closePopup = () => {
    const popup = document.getElementById("adoptionPopup");
    popup.style.display = "none";
  };

  window.onkeydown = function (event) {
    if (event.keyCode === 27) {
      closePopup();
    }
  };

  return (
    <div
      id="adoptionPopup"
      className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
    >
      <div
        onClick={closePopup}
        className="w-100 h-100 position-absolute outer-modal top-0 left-0"
      ></div>
      <div className="popup position-relative bg-white">
        <img
          onClick={closePopup}
          className="close-btn"
          src={iconClose}
          alt="close"
        />

        <div className="py-3 px-4">
          <div className="pb-0">
            <div className="d-flex justify-content-between">
              <h5 className="modal-title" id="chatModalLabel">
                Adoption Order &nbsp;
                <span className="fs-6 text-muted">#{adoption.id}</span>
              </h5>
              {adoption.status === 2 ? (
                <span className="badge text-bg-success fs-6 rounded-3">
                  Accepted
                </span>
              ) : adoption.status === 3 ? (
                <span className="badge text-bg-danger fs-6 rounded-3">
                  Rejected
                </span>
              ) : (
                <span className="badge text-bg-warning fs-6 rounded-3 text-light">
                  Pending
                </span>
              )}
            </div>

            <div className={"text-left"}>
              <span>Request Date: {adoption.order_date}</span>
            </div>
            <div className="product-modal-header-info px-0 pt-4">
              <div className="row">
                <div className="col-6 border-end">
                  <h6 className="fw-bold">Adopter Info</h6>
                  <div className={"text-center"}>
                    <img
                      src={adoption.pet_owner.picture}
                      height={100}
                      className="rounded-circle mb-3"
                      alt="User Image"
                      width="100"
                    />
                  </div>

                  <p className={"text-center"}>
                    <strong>
                      {adoption.pet_owner.first_name +
                        " " +
                        adoption.pet_owner.last_name}
                    </strong>
                  </p>

                  <div className="d-flex">
                    {chatMessageIcon}
                    <p className="ms-2 mb-2">{adoption.pet_owner.email}</p>
                  </div>
                  <div className="d-flex">
                    {phoneIcon}
                    <p className="ms-2 mb-2">
                      {adoption.pet_owner.phone_number}
                    </p>
                  </div>
                  <div className="d-flex">
                    {locationIcon}
                    <p className="ms-2 mb-2">
                      {adoption.pet_owner.addresses[0].area +
                        ", " +
                        adoption.pet_owner.addresses[0].city +
                        ", " +
                        adoption.pet_owner.addresses[0].state +
                        ", " +
                        adoption.pet_owner.addresses[0].country}
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <h6 className="fw-bold">Pet Info</h6>
                  <div className={"text-center"}>
                    <img
                      src={adoption.pet_profile.image}
                      height={100}
                      className="rounded-circle mb-3"
                      alt="Pet Image"
                      width="100"
                    />
                  </div>

                  <p className={"text-center"}>
                    <strong>{adoption.pet_profile.name}</strong>
                  </p>

                  <div className="d-flex">
                    <p className="ms-2 mb-2">
                      {adoption.pet_profile.description}
                    </p>
                  </div>
                  <div className="d-flex justify-content-center mt-3">
                    <button className={"btn btn-primary"}>
                      <Link to={`/admin/pets/view/${adoption.pet_profile.id}`} className="text-white">
                        Check Pet Profile
                      </Link>
                    </button>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdoptionRequestPopup;
