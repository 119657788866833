import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({
  apiKey = "kfyw7qfgp9f7gi82wndp4dxk4x7co8d2s7k2zvecsjenlw3f",
  value,
  onChange,
  placeholder,
  height = 200,
}) => {
  const handleEditorChange = (content) => {
    if (onChange) {
      onChange(content);
    }
  };

  const editorConfig = {
    apiKey,
    id: "description",
    name: "description",
    value,
    onEditorChange: handleEditorChange,
    placeholder,
    init: {
      height: height,
      menubar: false,
      plugins: [
        "advlist",
        "autolink",
        "lists",
        "link",
        "image",
        "charmap",
        "preview",
        "anchor",
        "searchreplace",
        "visualblocks",
        "code",
        "fullscreen",
        "insertdatetime",
        "media",
        "table",
        "code",
        "help",
        "wordcount",
      ],
      toolbar:
        "undo redo | blocks | " +
        "bold italic forecolor | alignleft aligncenter " +
        "alignright alignjustify | bullist numlist outdent indent | " +
        "removeformat | help",
      forced_root_block: "",
    },
  };

  return <Editor {...editorConfig} />;
};

export default TextEditor;
