import React, { useEffect, useState } from "react";
import photo from "../../../resources/themes/dashboard-v1/img/carouselPhoto.png";
import next from "../../../resources/themes/dashboard-v1/icons/arrow-right.svg";
import previous from "../../../resources/themes/dashboard-v1/icons/arrow-left.svg";
import iconClose from "../../../resources/themes/dashboard-v1/icons/close.svg";

import starSVG from "../../../resources/themes/dashboard-v1/icons/star.svg";
const ShopPopup = ({ shop }) => {
  if (shop === undefined) {
    return (
      <div
        id="shopPopup"
        className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
      ></div>
    );
  }

  const closePopup = () => {
    const popup = document.getElementById("shopPopup");
    popup.style.display = "none";
  };

  window.onkeydown = function (event) {
    if (event.keyCode === 27) {
      closePopup();
    }
  };

  return (
    <div
      id="shopPopup"
      className="popup-container vw-100 vh-100  jusitfy-content-center align-items-center p-md-0 p-2"
    >
      <div
        onClick={closePopup}
        className="w-100 h-100 position-absolute outer-modal top-0 left-0"
      ></div>
      <div className="popup position-relative bg-white">
        <img
          onClick={closePopup}
          className="close-btn"
          src={iconClose}
          alt="close"
        />
        <div className="d-flex justify-content-center pt-4 pb-5">
          <img
            src={shop.logo}
            className="d-block rounded-circle"
            width={120}
            height={120}
            alt="photo"
          />
        </div>

        <div className="py-3 px-4">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-4">
            <div className="d-flex flex-column gap-1">
              <span className="fs-4 fw-bold">{shop.name}</span>
              <span className="ligth-black">ID:#{shop.id}</span>
            </div>

            <span className="table-category adopted fw-bold text-white">
              {shop.type}
            </span>
          </div>

          <div className="border-bottom py-4 d-flex flex-column gap-4 ">
            <span className="fw-bold fs-5">Shop details</span>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  SHOP NAME
                </span>
                <span>{shop.name}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  SHOP AREA
                </span>
                <span>{shop.area}</span>
              </div>
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  SHOP CITY
                </span>
                <span>{shop.city}</span>
              </div>
            </div>

            <div className="d-flex flex-column">
              <span className="details-title light-black mb-1">
                SHOP DESCRIPTION
              </span>
              <span>{shop.description}</span>
            </div>

            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">
                  PHONE NUMBER
                </span>
                <span>
                  {shop.phone_number_cc}
                  {shop.phone_number}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="details-title light-black mb-1">LANDLINE</span>
                <span>
                  {shop.landline_cc}
                  {shop.landline}
                </span>
              </div>
              <div className="d-flex flex-column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopPopup;
