import React from "react";

const ListingCards = ({ list }) => {
  return (
    <div className="container">
      <div className="row">
        {list.map((item, i) => (
          <div key={i} className="col-md-3 mb-4">
            <a href={item.link}>
              <div
                className="card category-card rounded-3"
                style={{
                  backgroundImage: `url('${item.background_image}')`,
                }}
              >
                <div className="gradient-overlay rounded-3"></div>
                <div className="card-body d-flex align-items-end">
                  <h5 className="card-title text-white category-title">
                    {item.title}
                  </h5>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListingCards;
