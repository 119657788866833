import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import upload from "../../resources/themes/dashboard-v1/icons/upload.svg";
import close from "../../resources/themes/dashboard-v1/icons/close-icon.svg";
import camera from "../../resources/themes/dashboard-v1/icons/Camera.svg";
import { Navigate } from "react-router-dom";

import {
  handlePhoneNumberChange,
  handleLandlineChange,
  validate,
} from "./utils/business_info";
import SelectBox from "../dashboard/common/SelectBox";
import { saveRegistrationData } from "../../services/auth";

const BusinessInfo = (props) => {
  const [shopName, setShopName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");
  const [phoneNumberCC, setPhoneNumberCC] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [landline, setLandline] = useState("");
  const [landlineCC, setLandlineCC] = useState("");
  const [licenseName, setLicenseName] = useState("");
  const [license, setLicense] = useState(null);
  const [businessLogo, setBusinessLogo] = useState(null);
  const [saveData, setSaveData] = useState(false);
  const [generalErrorMessage, setGeneralErrorMessage] = useState(false);

  const [errors, setErrors] = useState({
    shopName: "",
    businessDescription: "",
    phoneNumber: "",
    licenseName: "",
    license: "",
    businessLogo: "",
    landline: "",
  });

  useEffect(() => {
    const formContainer = document.getElementById("form-container");
    if (formContainer) {
      formContainer.scrollTop = 0;
    }
  }, []);

  useEffect(() => {
    setBusinessLogo(businessLogo);
  }, [businessLogo]);

  async function handleSaveRegistration(data) {
    await saveRegistrationData(data)
      .then((response) => {
        console.log(response.message);
        if (response.status === "ok") {
          setGeneralErrorMessage("");
          setSaveData(true);
          props.updateTimeLine(4);
          console.log("Registration successful!");
        } else {
          props.updateTimeLine(5);
          setGeneralErrorMessage(response.message);
          console.error("Registration error:", response.message);
        }
      })
      .catch((error) => {
        setGeneralErrorMessage(
          "An error occurred while saving registration data."
        );
        props.updateTimeLine(6);
        console.error("Fetch error:", error);
      });
  }

  const handleNext = async (e) => {
    e.preventDefault();

    console.log(businessLogo);

    if (
      validate(
        shopName,
        businessDescription,
        phoneNumber,
        landline,
        licenseName,
        license,
        businessLogo,
        setErrors
      )
    ) {
      const data = await {
        ...props.registrationData,
        businessInfo: {
          shop_name: shopName,
          business_description: businessDescription,
          phone_number_cc: phoneNumberCC,
          phone_number: phoneNumber,
          landline_cc: landlineCC,
          landline: landline,
          business_logo: businessLogo,
          license_name: licenseName,
          license,
        },
      };

      props.setRegistrationData(data);
      handleSaveRegistration(data);
      setSaveData(true);
    }
  };

  const handleAddImage = (e) => {
    e.preventDefault();
    const fileInput = document.getElementById("BusinessLogoUpload");
    fileInput.click();
    fileInput.addEventListener("change", (event) => {
      if (event.target.files.length > 0) {
        setBusinessLogo(event.target.files[0]);
      }
    });
  };

  return (
    <form
      className={"full-width p-5"}
      encType="multipart/form-data"
      onSubmit={(e) => handleNext(e)}
    >
      <div className={`${props.class} business-info`}>
        <h2 className="mb-2 fw-bold">Business info</h2>

        <div>
          <p className="sec-title">BASIC INFO</p>

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">Shop Name</label>
            <input
              type="text"
              value={shopName}
              placeholder="shop name..."
              onChange={(e) => setShopName(e.target.value)}
            />
            {errors.shopName && (
              <div className="alert alert-danger mt-2">{errors.shopName}</div>
            )}
          </div>

          <div className="business-logo d-flex flex-column gap-1 input-container mb-md-0 mb-3 position-relative">
            <label className="fw-semibold">Business Logo</label>
            <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3 ">
              <div className="product-image w-100 position-relative">
                <div className="uploaded-images-container">
                  {businessLogo != null && (
                    <div className="uploaded-image">
                      {businessLogo instanceof File ? (
                        <img
                          height={120}
                          src={URL.createObjectURL(businessLogo)}
                          alt=""
                        />
                      ) : (
                        <img height={120} src={businessLogo} alt="" />
                      )}
                    </div>
                  )}

                  <div className="add-image-button d-flex justify-content-start">
                    <button onClick={handleAddImage}>+</button>
                    <input
                      id="BusinessLogoUpload"
                      type="file"
                      hidden
                      onChange={(e) => setBusinessLogo(e.target.files[0])}
                      accept="image/jpeg, image/png, image/gif"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errors.businessLogo && (
            <div className="alert alert-danger mt-2">{errors.businessLogo}</div>
          )}

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">Business Description</label>
            <textarea
              type="text"
              value={businessDescription}
              placeholder="Add a text that best describe your business..."
              onChange={(e) => setBusinessDescription(e.target.value)}
            />
            {errors.businessDescription !== "" && (
              <div className="alert alert-danger mt-2">
                {errors.businessDescription}
              </div>
            )}
          </div>

          <div className="d-flex flex-column gap-1 input-container mb-md-0 mb-3">
            <label className="fw-semibold">License Name</label>
            <input
              type="text"
              value={licenseName}
              placeholder="license name..."
              onChange={(e) => setLicenseName(e.target.value)}
            />
            {errors.licenseName && (
              <div className="alert alert-danger mt-2">
                {errors.licenseName}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label className="fw-semibold mb-1">Trade License</label>
            <div className="product-image w-100">
              {license == null && (
                <div className="upload-image">
                  <img src={upload} alt="upload" />
                  <p className="m-0 d-md-block d-none">
                    Upload Document or Drag& Drop
                  </p>
                </div>
              )}

              {license != null && (
                <div className="image-name">
                  <p className="m-0">{license.name}</p>
                  <img
                    onClick={() => setLicense(null)}
                    src={close}
                    alt="close"
                  />
                </div>
              )}
              <input
                onChange={(e) => setLicense(e.target.files[0])}
                className="full-width"
                type="file"
                accept="application/pdf"
              />
            </div>
            {errors.license !== "" && (
              <div className="alert alert-danger mt-2">{errors.license}</div>
            )}
          </div>

          <div className="mb-3 position-relative">
            <label className="fw-semibold mb-1">Phone Number</label>

            <PhoneInput
              defaultCountry="AE"
              international
              withCountryCallingCode
              value={phoneNumber}
              onChange={(value) =>
                handlePhoneNumberChange(value, setPhoneNumber, setPhoneNumberCC)
              }
            />
            {errors.phoneNumber && (
              <div className="alert alert-danger mt-2">
                {errors.phoneNumber}
              </div>
            )}
          </div>

          <div className="mb-3 position-relative">
            <label className="fw-semibold mb-1">Landline</label>

            <PhoneInput
              defaultCountry="AE"
              international
              withCountryCallingCode
              value={landline}
              onChange={(value) =>
                handleLandlineChange(value, setLandline, setLandlineCC)
              }
            />
            {errors.landline && (
              <div className="alert alert-danger mt-2">{errors.landline}</div>
            )}
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary py-2 mt-4 fw-bold w-100"
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default BusinessInfo;
