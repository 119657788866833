const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/dashboard-users";

const SEARCH_API =
  process.env.REACT_APP_DASHBOARD_API_URL + "/api/dash/search/top";

export const fetch_users = (options) => {
  const encodedQueryParams = new URLSearchParams(options);
  return fetch(`${API_HOST}?${encodedQueryParams}`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const delete_user = (id) => {
  return fetch(`${API_HOST}/${id}`, {
    method: "DELETE",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const export_users = () => {
  return fetch(API_HOST + "/export", {
    method: "POST",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const search_users = (searchQuery) => {
  return fetch(`${SEARCH_API}?query=${searchQuery}&type=user`, {
    method: "GET",
    credentials: "include",
    secure: true,
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};
