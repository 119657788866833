const API_HOST =
  process.env.REACT_APP_DASHBOARD_API_URL +
  "/api/dash/support-messages/";

export const send_support_message = (supportTicketId, messageBody, sender_id) => {
  const data = {
    message_body: messageBody,
    support_ticket_id: supportTicketId,
    sender_id: sender_id
  };

  return fetch(API_HOST, {
    method: "POST",
    credentials: "include",
    secure: true,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};

export const fetch_new_messages = (ticketId, lastFetchedTime) => {
  const url = `${API_HOST}${ticketId}/new?lastFetchedTime=${lastFetchedTime}`;

  return fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
};