import React from "react";
import PieChart from "./PieChart"; 
import LineChart from "./LineChart";

const DynamicChart = ({ type, data, width, height, color, isView }) => {
  if (type === "pie") {
    return (
      <PieChart
        data={data}
        width={width}
        height={height + 20}
        color={color}
        isView={isView}
      />
    );
  } else if (type === "line") {
    return (
      <LineChart
        data={data}
        width={width}
        height={height}
        color={color}
        isView={isView}
      />
    );
  } else {
    return null;
  }
};

export default DynamicChart;
