import React from 'react';
import {pawIcon} from "../../../resources/themes/dashboard-v1/icons/icons";

const PawCheckbox = ({ field, isChecked, handleInputChange }) => {
    return (
        <label className="bg-hover-offwhite rounded-1 dashed-border-lightgrey d-flex gap-1" key={field.name}>
            <div className="d-flex align-items-center justify-content-center p-3">
                <input
                    type="checkbox"
                    id={`${field.name}_checkbox`}
                    name={field.name}
                    checked={isChecked}
                    onChange={(event) =>
                        handleInputChange(field.name, event.target.checked)
                    }
                    className="checkbox checkbox-primary hidden-checkbox"
                />
                <span className="custom-checkbox"></span>
            </div>
            <span className="fw-semibold mb-0 p-3 pleft-0 flex-grow-1 cursor-pointer">
                {field.label}
            </span>
        </label>
    );
};

export default PawCheckbox;
